import React, { useState } from "react";
import PhoneFormStep3 from "./PhoneFormStep3";
import RightArrow from "../../../../assets//icons/rightArrow.svg";
import AddCertificate from "../AddCertificate";

function PhoneFormStep2(props) {
  const {
    name,
    tagline,
    email,
    number,
    location,
    gender,
    certificates,
    setCertificates,
  } = props;
  const [nextClicked, setNextClicked] = useState(false);
  return (
    <>
      {nextClicked ? (
        <PhoneFormStep3
          name={name}
          tagline={tagline}
          email={email}
          number={number}
          location={location}
          gender={gender}
        />
      ) : (
        <div className="phone_form_step2" style={{ padding: "1rem" }}>
          <AddCertificate
            certificates={certificates}
            setCertificates={setCertificates}
          />
          <div className="Phone_next_steps_btn">
            <p
              className="next_btn"
              onClick={() => {
                setNextClicked(!nextClicked);
              }}
            >
              2/7Next <img src={RightArrow} alt="" />
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default PhoneFormStep2;
