import React, { useState } from "react";
import { Link } from "react-router-dom";
import CoachImg from "../../../assets/img/coachImg.png";
import logoImg from "../../../assets/img/tofAppIcon1.png";
import "./coach.css";

function Coach(props) {
  const {
    isSignedIn,
    setOpen,
    setSignInOpen,
    coachSignUpStatus,
    setVerifiedClicked,
    verifiedClicked,
  } = props;
  const role = localStorage.getItem("role") ?? sessionStorage.getItem("role");
  let [isPlus, setPlus] = useState([false, false, false, false]);

  let changeCard = (index) => {
    const newPlusState = [...isPlus];
    newPlusState[index] = !newPlusState[index];
    setPlus(newPlusState);
  };

  const arr = [
    {
      id: "0",
      plus: "+",
      sub: "-",
      heding: "Unlock athlete potential with custom training plans.",
      para: "Empower your athletes to achieve their full potential with personalized training programs designed to enhance their performance. With continuous insights and motivating guidance, you can help them reach their goals and go beyond their limits. Join a vibrant community of athletes and coaches, and train anytime, anywhere to achieve success and excellence.",
    },
    {
      id: "1",
      plus: "+",
      sub: "-",
      heding: "Flexible coaching pricing for athletes",
      para: "Coaches are given full autonomy to create and manage their coaching plans. They can set their own prices based on their expertise and the services they offer. Athletes have the freedom to choose from a variety of plans that fit their goals and budget. This flexible approach ensures that athletes receive customized coaching services that are tailored to their needs and preferences, enriching their training experience.",
    },
    {
      id: "2",
      plus: "+",
      sub: "-",
      heding: "Adjust athlete training with the APP. ",
      para: "Coaches can use the app to monitor and adjust training plans and races, to improve overall athlete progress. By using key metrics such as speed, distance, and heart rate, coaches can set goals and adjust training for optimal performance. Whether they are on a desktop or mobile device, coaches can seamlessly oversee athlete profiles and track performance metrics",
    },
    // {
    //   id: "3",
    //   plus: "+",
    //   sub: "-",
    //   heding: "Manage everything in one place",
    //   para: "Enjoy the freedom and convenience of managing your coaching business from anywhere, at any time. With cross-platform support, you can access Tackofit's powerful coaching tools and resources from your desktop, laptop, tablet, or smartphone, ensuring that you're always connected to your athletes and able to provide the support they need. ",
    // },
  ];
  return (
    <div className="coach_div" id="coach">
      <div className="coach_heading">
        <h1>Become a coach</h1>
      </div>
      <div className="coach_info_box">
        <div className="coach_img_phone">
          <img src={CoachImg} alt="" />
        </div>
        <div className="coach_info">
          {arr.map((item) => (
            <div key={item.id} className="info">
              <div className="heading_box">
                <h4>{item.heding}</h4>
                <span onClick={() => changeCard(item.id)}>
                  {isPlus[item.id] ? item.sub : item.plus}
                </span>
              </div>
              {isPlus[item.id] && <p>{item.para}</p>}
            </div>
          ))}
        </div>
        <div className="coach_img">
          <img src={CoachImg} alt="" />
        </div>
      </div>
      <div className="join_box">
        <div className="join_text">
          <div className="join_heading">
            <h1>Ready to Join</h1>
            <img className="logo_img" src={logoImg} alt="" />
          </div>

          <p>
            Are you passionate about triathlon and eager to share your expertise
            with aspiring athletes? Join Tackofit as a coach and take your
            coaching journey to new heights. Here's what you can do as a coach
            on our platform
          </p>
        </div>
        <div className="join_button" style={{ textAlign: "center" }}>
          {isSignedIn ? (
            <>
              {role === "Coach" && <Link to="/ViewAccount">View Account</Link>}
              {role === "Athlete" &&
              coachSignUpStatus !== "Pending verfication" ? (
                <Link to="/CoachForm">Get Started</Link>
              ) : (
                <></>
              )}
              {coachSignUpStatus === "null" ||
                coachSignUpStatus === "3" ||
                (coachSignUpStatus === "5" && (
                  <Link to="/CoachForm">Get Started</Link>
                ))}
              {coachSignUpStatus === "Pending verfication" ? (
                <>
                  <Link to="/ViewCoachForm">Pending Verification</Link>
                  <p>(View Form)</p>
                </>
              ) : (
                <></>
              )}
              {coachSignUpStatus === "Verified" ? (
                <>
                  {" "}
                  <a
                    onClick={() => {
                      setVerifiedClicked(true);
                    }}
                  >
                    Verified
                  </a>
                  <div>
                    <p>Click to Switch to Coach Account</p>
                  </div>
                </>
              ) : (
                <></>
              )}
              {coachSignUpStatus === "Need Action" ? <a>Need Action</a> : <></>}
              {coachSignUpStatus === "View Profile" ? (
                <Link to="/ViewCoachForm">View Profile</Link>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <a
                onClick={() => {
                  setOpen(true);
                  setSignInOpen(true);
                }}
              >
                Get Started
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Coach;
