import axios from "axios";
import { AppConstants } from "../../app.constant";
import { Header } from "../../services/Header/Header";

export async function getCoachingPlans() {
  const header = Header();

  return axios
    .get(AppConstants.GET_COACHING_PLANS, {
      headers: header,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return res;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function AddCoachingPlans(
  planName,
  planDetails,
  planPrice,
  isTax
) {
  const header = Header();

  return axios
    .post(
      AppConstants.ADD_COACHING_PLANS,
      {
        plan_name: planName,
        plan_charge: planPrice,
        description: planDetails,
        is_tax_included: isTax,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        console.log(res);
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function UpdateCoachingPlan(
  planName,
  planDetails,
  planPrice,
  isTax,
  planId
) {
  const header = Header();
  return axios
    .post(
      AppConstants.UPDATE_COACHING_PLANS,
      {
        plan_name: planName,
        plan_charge: planPrice,
        description: planDetails,
        is_tax_included: isTax,
        plan_id: planId,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        console.log(res);
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function DeleteCoahingPlan(plan_id) {
  const header = Header();

  return axios
    .post(
      AppConstants.DELETE_COACHING_PLANS + plan_id,
      {},
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        console.log(res);
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}
