import React from "react";
import GarminLogo from "../../../assets//img//gramin-logo.png";
function ConnectedDevice() {
  return (
    <div className="setting_box">
      <div className="heading_edit_box">
        <h2 className="heading">Connected Device</h2>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <img
            style={{ width: "10rem" }}
            src="https://download.logo.wine/logo/Garmin/Garmin-Logo.wine.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default ConnectedDevice;
