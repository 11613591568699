import axios from "axios";
import { AppConstants } from "../../../app.constant";
import { Header } from "../../../services/Header/Header";
import jwt_decode from "jwt-decode";

export async function getFormData() {
  const header = Header();
  return axios
    .get(AppConstants.GET_COACH_FORM_DATA, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function getCoverPhoto() {
  const header = Header();
  return axios
    .get(AppConstants.GET_COVER_IMG, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function getProfilPhoto() {
  const header = Header();
  return axios
    .get(AppConstants.GET_PROFILE_IMG, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function SwitchAccount(ref_id) {
  const header = Header();
  const id = ref_id;

  return axios
    .get(AppConstants.SWITCH_ACCOUNT + `${id}`, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.token;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function SetDataInlocalStorage(token, RememberMe) {
  const decodedToken = jwt_decode(token);
  const userEmail = decodedToken["email"];
  const userName = decodedToken["name"];
  const userRole = decodedToken["role"];
  const ref_id = decodedToken["id"];
  if (RememberMe === true) {
    localStorage.setItem(AppConstants.JWT_TOKEN, token);
    localStorage.setItem("email", userEmail);
    localStorage.setItem("name", userName.trim());
    localStorage.setItem("role", userRole);
    localStorage.setItem("ref_id", ref_id);
    localStorage.setItem("remember_me", RememberMe);
  } else {
    sessionStorage.setItem(AppConstants.JWT_TOKEN, token);
    sessionStorage.setItem("email", userEmail);
    sessionStorage.setItem("name", userName.trim());
    sessionStorage.setItem("role", userRole);
    sessionStorage.setItem("ref_id", ref_id);
    sessionStorage.setItem("remember_me", RememberMe);
  }
}
