import React, { useState } from "react";
import OtpPopUp from "./OtpPopUp";
import { verifyEmail } from "../navController";
import { sendOtp } from "../navController";

function ForgorPasswordPopUp(props) {
  const { isCancel, setForgotPassword, isOpen, setOpen } = props;
  const [isSendOtp, setSendOtp] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(true);
  const [email, setEmail] = useState("");

  function handleCancel() {
    setForgotPassword(false);
    setOpen(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const res = await verifyEmail(email);
    if (res) {
      setSendOtp(true);
      await sendOtp(email);
    } else {
      setSendOtp(false);
      setEmailNotFound(false);
    }
  }

  return (
    <>
      {!isSendOtp ? (
        <form onSubmit={handleSubmit}>
          <div className="email_input_box">
            <span onClick={handleCancel} className="forgotPassword_cancel">
              Cancel
            </span>
            <h3 className="forgotPassword_heading">Forgot Password</h3>
            <input
              id="emailInput"
              className="input_box"
              type="email"
              name="email"
              autoComplete="email"
              placeholder="Enter your registered email address."
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            {!emailNotFound && <label htmlFor="">Not Found</label>}

            <div className="forgotPassword_submit">
              <button>Send OTP</button>
            </div>
          </div>
        </form>
      ) : (
        <OtpPopUp
          setOpen={setOpen}
          isOpen={isOpen}
          setForgotPassword={setForgotPassword}
          email={email}
        />
      )}
    </>
  );
}

export default ForgorPasswordPopUp;

// if (isCancel) {
//   setOpen(false);
//   setTimeout(() => {
//     setOpen(true);
//   }, 500);
// }
