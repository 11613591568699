import React, { useEffect, useState } from "react";
import "../css/coaching_plan.css";
import {
  DeleteCoahingPlan,
  getCoachingPlans,
} from "../MyCoachingPlansController";

function CoachingPlanCard(props) {
  const { handleEdit, add, handleRevomeId, planId } = props;
  const [data, setData] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);

  const [deleteText, setDeleteText] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCoachingPlans();
        setData(res.data.coaching_plans);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [add, deletePopup]);

  async function handleRevome() {
    const plan_id = planId;
    const res = await DeleteCoahingPlan(plan_id);
    console.log(res);
    if (res === true) {
      setDeletePopup(false);
    } else {
      alert("not deleted");
    }
  }

  return (
    <>
      {data.length === 0 ? (
        <></>
      ) : (
        <>
          {data.map((plan) => (
            <div key={plan.plan_id}>
              {deletePopup && (
                <div>
                  <div className=" delete_blur"></div>
                  <div className="delete_popup">
                    <div className="cancel_btn">
                      <p onClick={() => setDeletePopup(false)}>Cancel</p>
                    </div>
                    <div className="delete_heading">
                      <h6>Remove Plan</h6>
                    </div>
                    {/* <div className="delete_input">
                      <label htmlFor="">Type "Delete" to delete the plan</label>
                      <input
                        type="text"
                        onChange={(e) => setDeleteText(e.target.value)}
                      />
                    </div> */}
                    <div className="btns">
                      <div className="delete_btn_n">
                        <p onClick={() => setDeletePopup(false)}>No</p>
                      </div>
                      <div className="delete_btn">
                        <p onClick={() => handleRevome()}>YES</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="card_body">
                <div className="count_btn">
                  {plan.type === 1 && (
                    <>
                      {plan.active_count === plan.max_count ? (
                        <span style={{ color: "#FF0000" }}>
                          {plan.active_count}/{plan.max_count}
                        </span>
                      ) : (
                        <span>
                          {plan.active_count}/{plan.max_count}
                        </span>
                      )}
                    </>
                  )}
                  {plan.type === 2 && <span>{plan.active_count}</span>}
                </div>

                {plan.type === 1 ? (
                  <div className="edit_remove_div" style={{ opacity: "0" }}>
                    <p className="edit_btn">e</p>
                    <p className="remove_btn">r</p>
                  </div>
                ) : (
                  <div className="edit_remove_div">
                    <p
                      className="edit_btn"
                      onClick={() =>
                        handleEdit(
                          plan.plan_id,
                          plan.plan_name,
                          plan.description,
                          plan.plan_charge
                        )
                      }
                    >
                      Edit
                    </p>
                    <p
                      className="remove_btn"
                      onClick={() => {
                        handleRevomeId(plan.plan_id);
                        setDeletePopup(true);
                      }}
                    >
                      Remove
                    </p>
                  </div>
                )}

                <div className="card_heading">
                  <h4>{plan.plan_name}</h4>
                  <h5>
                    {plan.description === null ? <></> : plan.description[0]}
                  </h5>
                </div>
                <div className="card_info">
                  <p>
                    {plan.description === null ? <></> : plan.description[1]}
                  </p>
                </div>
                {plan.type === 1 ? (
                  <div className="card_btn">
                    <p>Free Plan</p>
                  </div>
                ) : (
                  <div className="card_btn">
                    <p> ₹ {plan.plan_charge}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default CoachingPlanCard;
