import React, { useEffect, useState } from "react";
import { SubmitCoachForm } from "../../FormController";

function PhoneFormStep7(props) {
  const {
    name,
    tagline,
    email,
    number,
    location,
    gender,
    aboutYourSelf,
    selectedDistancesRuning,
    selectedDistancesSwim,
    selectedDistancesCycle,
  } = props;
  const [isYes, setYes] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [TAC, setTAC] = useState(false);
  const [selectedDistancesTriathlon, setSelectedDistancesTriathlon] = useState(
    []
  );
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (isYes === "" || TAC === false) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    if (isYes === "yes" || isYes === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [isYes, TAC]);
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    if (selectedDistancesTriathlon.includes(value)) {
      setSelectedDistancesTriathlon(
        selectedDistancesTriathlon.filter((item) => item !== value)
      );
    } else {
      // Otherwise, add it to the array
      setSelectedDistancesTriathlon([...selectedDistancesTriathlon, value]);
    }
  };
  async function handleSubmit(e) {
    e.preventDefault();
    console.log("submit clickewd");
    const req = await SubmitCoachForm(
      name,
      tagline,
      email,
      number,
      location,
      gender,
      aboutYourSelf,
      selectedDistancesRuning,
      selectedDistancesSwim,
      selectedDistancesCycle,
      selectedDistancesTriathlon
    );

    if (req === true) {
      alert("form submited");
    } else {
      alert("error");
    }
  }
  return (
    <div>
      {nextClicked && validation ? (
        <></>
      ) : (
        <div className="phone_form_step7" style={{ marginTop: "4rem" }}>
          <div className="step2_div">
            <div className="question_1 ">
              <h4>Do you train athletes for Triathlon ?</h4>
              <div className="yes_no_div tir">
                <label>
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setYes("yes")}
                  />
                  <span>Yes</span>
                </label>

                <label>
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setYes("no")}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: ".5rem" }}>
              <input
                type="checkbox"
                id="terms"
                value="TAC"
                onChange={() => setTAC(!TAC)}
                style={{ border: "black" }}
              />
              <label htmlFor="terms">
                I agree to the Terms of use,{" "}
                <a href="https://www.trackofit.com/privacypolicy.html">
                  Privacy Policy{" "}
                </a>
                and <a href="">Refund Policy</a>
              </label>
            </div>
            {isYes === "yes" ? (
              <>
                <div className="question_2">
                  <h4>Select distances</h4>
                  <div className="yes_no_div tir">
                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        onChange={() => handleCheckboxChange("Sprint Tri")}
                      />
                      <span>Sprint Tri</span>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        id=""
                        onChange={() => handleCheckboxChange("5150 ironman")}
                      />
                      <span>5150 ironman</span>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        id=""
                        onChange={() => handleCheckboxChange("Ironman 70.3")}
                      />
                      <span>Ironman 70.3</span>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        id=""
                        onChange={() => handleCheckboxChange("Ironman")}
                      />
                      <span>Ironman</span>
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="Coach_from_submit_div">
              <button
                className="Coach_from_submit"
                type="submit"
                onClick={handleSubmit}
                disabled={disable}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PhoneFormStep7;
