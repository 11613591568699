import React, { useEffect, useState } from "react";
import PhoneNavBar from "../NavBarM/PhoneComponents/PhoneNavBar.jsx";
import Nav from "../NavBarM/DesktopComponents/Nav.jsx";
import Download from "../../assets/icons/download.svg";
import Info from "../../assets/icons/info.svg";
import "rsuite/dist/styles/rsuite-default.css";
import { Button, Popover, Whisper } from "rsuite";
import More from "../../assets/icons/more.svg";
import { compareDate } from "./MybillingController.jsx";
import { PaynowBox } from "./MybillingController.jsx";
import PopUpLoginP from "../NavBarM/PhoneComponents/PopUpLoginP.jsx";
import "./mybilling.css";
import { getSessionId } from "./MybillingController.jsx";
import { load } from "@cashfreepayments/cashfree-js";
import { useNavigate, Link } from "react-router-dom";

function MyBilling(props) {
  const {
    isOpen,
    setOpen,
    isOpenP,
    setOpenP,
    isSignedIn,
    userInitial,
    setIsSignedIn,
    isSwitchBtn,
    SwitchAccountBtn,
  } = props;
  const myBilling = true;
  const navigateToBilling = useNavigate();
  const [isMore, setMore] = useState(false);
  const [isData, setData] = useState(false);
  let dateGap;
  let cashfree;
  const planDate = compareDate();

  useEffect(() => {
    const token =
      localStorage.getItem("token") ?? sessionStorage.getItem("token");

    if (token) {
      return;
    } else {
      navigateToBilling("/");
    }
  });

  // JSON
  const BillingData = [
    {
      id: "TS2425-0011",
      billing_period_from: "08-04-2024",
      billing_period_to: "08-05-2024",
      payment_status_code: 1,
      discount: 0.0,
      taxes: 957.63,
      total: 3000.0,
      status: "Unpaid",
      "paid on": "07-04-2024 10:10:10",
    },
    {
      id: "TS2425-0343",
      billing_period_from: "15-03-2024",
      billing_period_to: "15-04-2024",
      payment_status_code: 1,
      discount: 50.0,
      taxes: 625.0,
      total: 2000.0,
      status: "Paid",
      "paid on": "16-03-2024 09:30:45",
    },
    {
      id: "TS2425-0354",
      billing_period_from: "01-05-2024",
      billing_period_to: "01-06-2024",
      payment_status_code: 2,
      discount: 0.0,
      taxes: 380.0,
      total: 1500.0,
      status: "Paid",
    },
    {
      id: "TS2425-0031",
      billing_period_from: "20-02-2024",
      billing_period_to: "20-03-2024",
      payment_status_code: 3,
      discount: 20.0,
      taxes: 200.0,
      total: 1000.0,
      status: "Paid",
    },
  ];

  //insitialze cashfree res
  let insitialzeSDK = async function () {
    cashfree = await load({
      mode: "sandbox",
    });
  };
  insitialzeSDK();
  //------------------------------------

  // PayNow click fuction
  async function handlePayNow(e) {
    e.preventDefault();
    function checkPopUpBlocker() {
      // Attempt to open a new window
      const popupWindow = window.open("", "_blank", "width=100,height=100");

      // Check if the window was blocked by the browser's pop-up blocker
      if (popupWindow === null || typeof popupWindow === "undefined") {
        alert("Pop-up blocker is enabled .");
        return true;
      } else {
        // Close the window if it was successfully opened
        popupWindow.close();

        return false;
      }
    }

    // Call the function to check for pop-up blocker
    const popUpBlocked = checkPopUpBlocker();

    // cashfree code for order checkout
    try {
      const sessionId = await getSessionId();
      let checkoutOptions = {
        paymentSessionId: sessionId,
        redirectTarget: "_modal",
      };

      cashfree.checkout(checkoutOptions).then((res) => {});
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Nav
        isOpen={isOpen}
        setOpen={setOpen}
        userInitial={userInitial}
        isSignedIn={isSignedIn}
        myBilling={myBilling}
        isSwitchBtn={isSwitchBtn}
        SwitchAccountBtn={SwitchAccountBtn}
      />
      <PhoneNavBar
        isOpenP={isOpenP}
        setOpenP={setOpenP}
        isSignedIn={isSignedIn}
        userInitial={userInitial}
        myBilling={myBilling}
      />
      <PopUpLoginP
        isOpenP={isOpenP}
        setOpenP={setOpenP}
        setIsSignedIn={setIsSignedIn}
        isSignedIn={isSignedIn}
        userInitial={userInitial}
      />
      {isData ? (
        <>
          <div className="billing_section">
            {/* ----- heading box --------*/}
            <div className="billing_heading">
              <h1>
                {" "}
                <Link to="/">&#10094; My Billing</Link>
              </h1>
            </div>
            {/* ---- Billing Details box -----  */}
            <div className="billing_detail_box">
              <div className="billing_detail_heading">
                <p>YOUR MEMBERSHIP</p>
              </div>
              <div className="plan_box">
                <div className="plan_name">
                  <p>Plan</p>
                  <p>
                    HALF MARATHON LEVEL 2 <br />
                    5000$/month
                  </p>
                  <p className="grey_text" style={{ fontSize: ".8rem" }}>
                    Plan period: {PaynowBox.billing_period_from} To{" "}
                    {PaynowBox.billing_period_to}
                  </p>
                </div>

                <div className="plan_due_date">
                  <div>
                    <p>{planDate ? "Due date was" : "Due Date"}</p>
                    <p style={{ display: "inline-block" }}>
                      {PaynowBox.billing_period_from}
                    </p>
                    {planDate && (
                      <span
                        style={{
                          fontSize: ".8rem",
                          color: "#DC0000",
                          marginLeft: ".4rem",
                        }}
                        className="grey_text"
                      >
                        Over due by {planDate} days
                      </span>
                    )}
                  </div>

                  <div className="paynow_div">
                    <button onClick={handlePayNow} className="pay_btn">
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
              {/* ---- Billing Details last para -------*/}
              <div className="billinglast_para">
                <p>
                  Membership fees are billed at the beginning of each period and
                  may take time the billing Details appear on your account
                </p>
              </div>
            </div>
            ;<div className="para_bottom_line"></div>
            {/*-------- Billing History Box desktop =------*/}
            <div className="billing_history_box">
              <div className="billing_filter">
                <span className="grey_text">View</span>
                <select name="" id="">
                  All Entries
                </select>
              </div>
              <div className="billing_his_data">
                <div className="billing_his_heading">
                  <h4>Invoice id</h4>
                  <h4>Invoice Date </h4>
                  <h4>Due Date</h4>
                  <h4 className="Billing_period">Billing period </h4>
                  <h4>Total </h4>
                  <h4>Status</h4>
                  <h4>Invoice </h4>
                </div>
                {BillingData.map((items) => (
                  <div className="billing_data1">
                    <p>1232324#</p>
                    <p>{items.billing_period_from}</p>
                    <p>{items.billing_period_to}</p>
                    <p>
                      {items.billing_period_from +
                        " To " +
                        items.billing_period_to}
                    </p>
                    <p>₹{items.total}</p>
                    <p
                      style={{
                        color: items.status === "Paid" ? "#04B801" : "#DC0000",
                      }}
                    >
                      &#9679; {items.status}
                    </p>
                    <p className="download_icon">
                      <img src={Download} alt="" />
                    </p>
                    <Whisper
                      followCursor
                      placement="leftEnd"
                      speaker={
                        <Popover
                          style={{
                            boxShadow: " rgba(0, 0, 0, 0.247) 0px 0px 4px 0px",
                          }}
                        >
                          <div className="popup_box">
                            <div className="popup_plan">
                              <p className="grey_text">Plan</p>
                              <p>HALF MARATHON LEVEL 2</p>
                            </div>
                            <div className="popup_dates">
                              <p className="grey_text">Paid on</p>
                              <p>5/3/2024</p>
                            </div>
                            <div className="popup_total_amount">
                              <p className="grey_text">Plan Amount :</p>
                              <p>₹5500</p>
                            </div>
                            <div className="popup_discount">
                              <p className="grey_text"> Discount :</p>
                              <p>- ₹500</p>
                            </div>
                            <div className="popup_paid">
                              <p className="grey_text">Amount Paid :</p>
                              <p>₹5000</p>
                            </div>
                          </div>
                        </Popover>
                      }
                    >
                      <img
                        onClick={() => setMore(!isMore)}
                        style={{ padding: "1.3rem 0" }}
                        src={More}
                        alt=""
                      />
                    </Whisper>
                  </div>
                ))}
              </div>

              {/* ---- Billing Paging section ---------*/}
              <div className="billing_paging_section">
                <div>
                  <span className="grey_text">Show</span>
                  <select name="" id="">
                    10
                  </select>
                  <span className="grey_text">entries</span>
                </div>

                <div>
                  <span className="previous_btn">Previous</span>
                  <span className="page_no">1/9</span>
                  <span className="next_btn">Next</span>
                </div>
              </div>
            </div>
            {/* ----- Billing History Card Phone ------ */}
            {BillingData.map((items) => (
              <div className="billing_history_card">
                {/* Invoice date, due date, Invoice id section */}
                <div className="info_button">
                  <Whisper
                    followCursor
                    placement="leftEnd"
                    speaker={
                      <Popover
                        style={{
                          boxShadow: " rgba(0, 0, 0, 0.247) 0px 0px 4px 0px",
                        }}
                      >
                        <div className="popup_box">
                          <div className="popup_plan">
                            <p className="grey_text">Plan</p>
                            <p>HALF MARATHON LEVEL 2</p>
                          </div>
                          <div className="popup_dates">
                            <p className="grey_text">Paid on</p>
                            <p>5/3/2024</p>
                          </div>
                          <div className="popup_total_amount">
                            <p className="grey_text">Plan Amount :</p>
                            <p>₹5500</p>
                          </div>
                          <div className="popup_discount">
                            <p className="grey_text"> Discount :</p>
                            <p>- ₹500</p>
                          </div>
                          <div className="popup_paid">
                            <p className="grey_text">Amount Paid :</p>
                            <p>₹5000</p>
                          </div>
                        </div>
                      </Popover>
                    }
                  >
                    <img src={Info} alt="info" />
                  </Whisper>
                </div>
                <div className="date_header">
                  <div className="invoice_date">
                    <div className="invoice_date_div">
                      <p className="grey_text">Invoice Date</p>
                      <p>{items.billing_period_from}</p>
                    </div>
                    <div className="invoice_date_div">
                      <p className="grey_text">Due Date</p>
                      <p>{items.billing_period_to}</p>
                    </div>
                  </div>
                  <div className="invoice_id">
                    <p className="grey_text">Invoice Id</p>
                    <p>1234#</p>
                  </div>
                </div>
                {/* -- Service period , Status section -- */}
                <div className="service_status">
                  <div className="service_period">
                    <p className="grey_text">Servie period</p>
                    <p>
                      {items.billing_period_from +
                        " TO " +
                        items.billing_period_to}
                    </p>
                  </div>
                  <div className="status">
                    <p className="grey_text ">Status</p>
                    <p
                      style={{
                        color: items.status === "Paid" ? "#04B801" : "#DC0000",
                      }}
                      className="paid"
                    >
                      &#9679; {items.status}
                    </p>
                  </div>
                </div>
                {/* -- download, total section -- */}
                <div className="download_price">
                  <p className=" download_invoice grey_text">
                    Download Invoice
                  </p>
                  <p className="total">₹{items.total}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="no_history">
            <div className="billing_heading">
              <h1>
                {" "}
                <Link to="/">&#10094; My Billing</Link>
              </h1>
              <h3>No Billing History</h3>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MyBilling;

// Popup placement
// topStart
// top
// topEnd
// leftStart
// left
// leftEnd
// rightStart
// right
// rightEnd
// bottomStart
// bottom
// bottomEnd
