import React from "react";
import "./css/view-acc-footer.css";
import { Link } from "react-router-dom";
function ViewAccFooter(props) {
  const { profileType } = props;
  return (
    <div className="View_acc_footer">
      {profileType ? (
        <></>
      ) : (
        <a href="#MyBilling">
          <div className="billing_box">
            <h2 className="heading">MY BILLING</h2>
            <p>&#8250;</p>
          </div>
        </a>
      )}
      <a href="https://www.trackofit.com/privacypolicy.html" target="_blank">
        <div className="billing_box">
          <h2 className="heading">SECURITY & PRIVACY</h2>
          <p>&#8250;</p>
        </div>
      </a>

      <a href="#">
        <div className="billing_box">
          <h2 className="heading">DELETE ACCOUNT</h2>
          <p>&#8250;</p>
        </div>
      </a>
    </div>
  );
}

export default ViewAccFooter;
