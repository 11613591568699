import { addPosts } from "../../services/Header/Header";
import { addPostsNoRememberMe } from "../../services/Header/Header";
import { Buffer } from "buffer";
import { AppConstants } from "../../app.constant";
import axios from "axios";

export async function SubmitData(email, password, RememberMe, e) {
  e.preventDefault();
  const buffer = Buffer.from(password);
  const encrPassword = buffer.toString("base64");
  try {
    if (RememberMe) {
      const request = await addPosts(email, encrPassword, RememberMe);
      return request;
    }
    if (!RememberMe) {
      const request = await addPostsNoRememberMe(email, encrPassword);
      return request;
    }
  } catch (error) {
    console.error("Login failed:", error);
    return false;
  }
}

export async function verifyEmail(email) {
  return axios

    .post(AppConstants.REST_API_VERIFY_EMAIL, {
      email: email,
    })

    .then((response) => {
      if (response.data.status_code === 1) {
        console.log(response);
        return true;
      } else {
        console.log(response);
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

export async function sendOtp(email) {
  return axios

    .post(AppConstants.REST_API_SEND_OTP, {
      email: email,
    })
    .then((response) => {
      console.log(response);
      return true;
    })
    .catch((error) => {
      return error;
    });
}

export async function verifyOtp(email, otpNo) {
  return axios

    .post(AppConstants.REST_API_VERIFY_OTP, {
      email: email,
      otp: otpNo,
    })
    .then((response) => {
      if (response.data.status_code === 1) {
        console.log(response);
        return true;
      } else if (response.data.status_code === 2) {
        return false;
      }
    })
    .catch((error) => {
      console.log("error");
      return error;
    });
}

export async function updatePassword(email, password) {
  return axios
    .post(AppConstants.REST_API_UPDATE_PASSWORD, {
      email: email,
      password: password,
    })
    .then((response) => {
      if (response.data.status_code === 1) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function submitDataSignUp(
  name,
  email,
  password,
  activity,
  gender,
  TAC,
  dob
) {
  return axios
    .post(AppConstants.USER_SIGN_UP, {
      name: name,
      email: email,
      older_than_18: true,
      password: password,
      send_marketing: true,
      athlete_type: activity,
      accept_tnc: TAC,
      gender: gender,
      dob: dob,
    })
    .then((response) => {
      if (response.data.status_code === 1) {
        console.log(response);
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
