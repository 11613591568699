import React, { useEffect, useState } from "react";
import PhoneFormStep5 from "./PhoneFormStep5";
import RightArrow from "../../../../assets/icons/rightArrow.svg";

function PhoneFormStep4(props) {
  const { name, tagline, email, number, location, gender, aboutYourSelf } =
    props;
  const [isYes, setYes] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [selectedDistancesRuning, setSelectedDistancesRunning] = useState([]);
  const [popper, setPopper] = useState(false);
  useEffect(() => {
    if (isYes === "yes" || isYes === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    if (setSelectedDistancesRunning.includes(value)) {
      setSelectedDistancesRunning(
        setSelectedDistancesRunning.filter((item) => item !== value)
      );
    } else {
      // Otherwise, add it to the array
      setSelectedDistancesRunning([...selectedDistancesRuning, value]);
    }
  };

  return (
    <div>
      {nextClicked && validation ? (
        <PhoneFormStep5
          name={name}
          tagline={tagline}
          email={email}
          number={number}
          location={location}
          gender={gender}
          aboutYourSelf={aboutYourSelf}
          selectedDistancesRuning={selectedDistancesRuning}
        />
      ) : (
        <div className="phone_form_step4" style={{ marginTop: "4rem" }}>
          <div className="question_1">
            <h4>Do you train athletes for running ?</h4>
            <div className="yes_no_div">
              <label>
                <input
                  type="radio"
                  name="radio"
                  onChange={() => setYes("yes")}
                />
                <span>Yes</span>
              </label>

              <label>
                <input
                  type="radio"
                  name="radio"
                  onChange={() => setYes("no")}
                />
                <span>No</span>
              </label>
            </div>
          </div>

          {isYes === "yes" ? (
            <>
              <div className="question_2">
                <h4>Select distances</h4>
                <div className="yes_no_div">
                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      onChange={() => handleCheckboxChange("5k")}
                    />
                    <span>5k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      onChange={() => handleCheckboxChange("7k")}
                    />
                    <span>7k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      onChange={() => handleCheckboxChange("10k")}
                    />
                    <span>10k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      onChange={() => handleCheckboxChange("16k")}
                    />
                    <span>16k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      onChange={() => handleCheckboxChange("21k")}
                    />
                    <span>21k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      onChange={() => handleCheckboxChange("42k")}
                    />
                    <span>42k</span>
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="Phone_next_steps_btn">
            {popper && (
              <div className="popper">
                <p>Fill up the fileds</p>
              </div>
            )}
            <p
              className="next_btn"
              onClick={() => {
                if (validation === true) {
                  setNextClicked(true);
                  setPopper(false);
                } else {
                  if (validation === false) {
                    setPopper(true);
                  }
                }
              }}
            >
              4/7Next <img src={RightArrow} alt="" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PhoneFormStep4;
