import React, { useEffect, useState } from "react";
import { SubmitCoachForm } from "../../FormController";
import { useNavigate } from "react-router-dom";
function FormStep5(props) {
  const {
    name,
    tagline,
    email,
    number,
    location,
    gender,
    aboutYourSelf,
    selectedDistancesRun,
    selectedDistancesSwim,
    selectedDistancesCycle,
    isRun,
    coverPhotoPath,
    profilePhotoPath,
    certificates,
    isSwim,
  } = props;
  const navigate = useNavigate();
  const [isTriathlon, setTriathlon] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [TAC, setTAC] = useState(false);
  const [selectedDistancesTriathlon, setSelectedDistancesTriathlon] = useState(
    []
  );
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (isTriathlon === "" || TAC === false) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    if (isTriathlon === "yes" || isTriathlon === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [isTriathlon, TAC]);
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    if (selectedDistancesTriathlon.includes(value)) {
      setSelectedDistancesTriathlon(
        selectedDistancesTriathlon.filter((item) => item !== value)
      );
    } else {
      // Otherwise, add it to the array
      setSelectedDistancesTriathlon([...selectedDistancesTriathlon, value]);
    }
  };

  async function handleSubmit(e) {
    console.log(TAC);
    console.log(coverPhotoPath);
    console.log(profilePhotoPath);
    e.preventDefault();
    try {
      const req = await SubmitCoachForm(
        name,
        tagline,
        email,
        number,
        location,
        gender,
        aboutYourSelf,
        selectedDistancesRun,
        selectedDistancesSwim,
        selectedDistancesCycle,
        selectedDistancesTriathlon,
        coverPhotoPath,
        profilePhotoPath,
        certificates,
        TAC
      );

      if (req === true) {
        navigate("/");
      } else {
        alert("error start again");
      }
    } catch {
      alert("error");
    }
  }

  return (
    <div>
      {nextClicked && validation ? (
        <></>
      ) : (
        <div className="step2_div">
          <div className="question_1 ">
            <h4>Do you train athletes for Triathlon ?</h4>
            <div className="yes_no_div tir">
              <label htmlFor="yes">
                <input
                  type="radio"
                  name="radio"
                  id="yes"
                  onChange={() => setTriathlon("yes")}
                />
                <span>Yes</span>
              </label>

              <label htmlFor="no">
                <input
                  type="radio"
                  name="radio"
                  id="no"
                  onChange={() => setTriathlon("no")}
                />
                <span>No</span>
              </label>
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: ".5rem" }}>
            <input
              type="checkbox"
              id="terms"
              value="TAC"
              onChange={() => setTAC(!TAC)}
              style={{ border: "black" }}
            />
            <label htmlFor="terms">
              I agree to the Terms of use,{" "}
              <a href="https://www.trackofit.com/privacypolicy.html">
                Privacy Policy{" "}
              </a>
              and <a href="">Refund Policy</a>
            </label>
          </div>

          {isTriathlon === "yes" ? (
            <>
              <div className="question_2">
                <h4>Select distances</h4>
                <div className="yes_no_div tir">
                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      value="Sprint Tri"
                      onChange={() => handleCheckboxChange("Sprint Tri")}
                    />
                    <span>Sprint Tri</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="5150 ironman"
                      onChange={() => handleCheckboxChange("5150 ironman")}
                    />
                    <span>5150 ironman</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="Ironman 70.3"
                      onChange={() => handleCheckboxChange("Ironman 70.3")}
                    />
                    <span>Ironman 70.3</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="Ironman"
                      onChange={() => handleCheckboxChange("Ironman")}
                    />
                    <span>Ironman</span>
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="Coach_from_submit_div">
            <button
              className="Coach_from_submit"
              type="submit"
              onClick={handleSubmit}
              disabled={disable}
            >
              SUBMIT
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default FormStep5;
