import React, { useState } from "react";

function Certificate() {
  const [isEdit, setEdit] = useState(false);
  function handleSave() {}
  return (
    <div className="setting_box">
      <div className="heading_edit_box">
        <h2 className="heading">Certificate</h2>
        {isEdit ? (
          <p className="edit_button" onClick={handleSave}>
            Save
          </p>
        ) : (
          <p className="edit_button" onClick={() => setEdit(true)}>
            Edit
          </p>
        )}
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Certificate name</p>
          <p className="profile_title">&ndash; </p>
        </div>
        <div className="second_half">
          <p className="profile_lable">Certificate issued by</p>
          <p className="profile_title">&ndash; </p>
        </div>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Certificate issued on</p>
          <p className="profile_title">&ndash; </p>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
