import { useEffect, useRef, useState } from "react";
import { sendOtp } from "../../navController";
import { verifyOtp } from "../../navController";
import { Button } from "rsuite";
import "rsuite/dist/styles/rsuite-default.min.css";
import "../../css/popup.css";
import "../../css/nav.css";
import "../../css/signup.css";
const SendOtp = ({
  length = 6,
  onOtpSubmit = () => {},
  setDone,
  email,
  setBlur,
  setDisable,
  setSendOtp,
  setCount,
  count,
  setOtpVerify,
  otpVerify,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  const [load, setLoad] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [counter, setCounter] = useState(120);
  const otpNo = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];

  useEffect(() => {
    setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendOTP(true);
      setCounter(120);
    }
  }, [counter]);

  async function handleResendOTP() {
    if (count > 3) {
      setResendOTP(true);
      alert("try after some time");
      setDone(true);
      setBlur(false);
    } else {
      await sendOtp(email);
      setResendOTP(false);
      setCount(count + 1);
    }
  }

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await verifyOtp(email, otpNo);
    if (res === true) {
      setLoad(false);
      setOtpVerify(true);
      setInvalidOtp(false);
      setDone(true);
      setBlur(false);
      setDisable(false);
    } else {
      setLoad(false);
      setOtpVerify(false);
      setInvalidOtp(true);
    }
  }

  return (
    <>
      {!otpVerify ? (
        <div className="otp_signup_form">
          <form>
            <div className="otp_box signup_otp_box">
              <span
                className="forgotPassword_cancel"
                onClick={() => {
                  setSendOtp(false);
                  setBlur(false);
                }}
              >
                Cancel
              </span>
              <h3 className="forgotPassword_heading">Enter OTP</h3>
              <p className="forgotPassword_message  signup_forgotPassword_message">
                A One-time-password has been sent to your registered email
                address. Check your Inbox, including Spam/Junk.
              </p>
              <div className="otp_inputs">
                {otp.map((value, index) => {
                  return (
                    <input
                      key={index}
                      type="text"
                      ref={(input) => (inputRefs.current[index] = input)}
                      value={value}
                      onChange={(e) => handleChange(index, e)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      className="otpInput"
                    />
                  );
                })}
              </div>
              <div className="error_message_resend_div">
                <p className="forgotPassword_message resend_otp_text signup_resend_otp_text">
                  Didn’t receive OTP?
                  {resendOTP ? (
                    <span onClick={handleResendOTP}>Resend</span>
                  ) : (
                    <span> {counter}</span>
                  )}
                </p>
                {invalidOtp && (
                  <span className="signup_invalid_otp">Invalid OTP</span>
                )}
              </div>

              <div className="signup_forgotPassword_submit forgotPassword_submit">
                <button onClick={handleSubmit}>
                  {load ? (
                    <Button
                      loading
                      className="submit_box"
                      style={{ backgroundColor: "#fdc027" }}
                    ></Button>
                  ) : (
                    <span>Done</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
export default SendOtp;
