import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import img from "../../../assets/img/about2_img.jpg";
import {
  getCoverPhoto,
  getFormData,
  getProfilPhoto,
} from "./viewCoachFormController";

import { Link } from "react-router-dom";
import Nav from "../../NavBarM/DesktopComponents/Nav";
import PhoneNavBar from "../../NavBarM/PhoneComponents/PhoneNavBar";
import PopUpLoginP from "../../NavBarM/PhoneComponents/PopUpLoginP";
import "./viewcoachform.css";
function ViewCoachForm(props) {
  const {
    isOpen,
    setOpen,
    isOpenP,
    setOpenP,
    isSignedIn,
    userInitial,
    setIsSignedIn,
    isSwitchBtn,
    SwitchAccountBtn,
  } = props;

  const [getData, setData] = useState([]);
  const [CoverImgSrc, setCoverImgSrc] = useState("");
  const [profileImgSrc, setProfileImgSrc] = useState("");
  const [byteCover, setByteCover] = useState("");
  const [byteProfile, setByteProfile] = useState("");
  const [certifications, setCertifications] = useState([]);
  const byteArray = byteCover;
  const byteArray1 = byteProfile;
  //API call get data for coach Form

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getFormData();
        setData(res.data);
        setCertifications(getData.certifications);
        if (res.data.cover_photo || res.data.profile_photo) {
          const resCoverPhoto = await getCoverPhoto();
          const resProfilePhoto = await getProfilPhoto();
          setByteCover(resCoverPhoto.data);
          setByteProfile(resProfilePhoto.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (byteArray) {
      // console.log("hit");
      // // const base64String = byteArrayToBase64(byteArray);
      // // setCoverImgSrc(`data:image/png;base64,${base64String}`);
      // var x = btoa(String.fromCharCode(new Uint8Array(byteCover)));
      // console.log(x);
      // setCoverImgSrc("data:image/jpg;base64," + x);

      const buffer = Buffer.from(byteCover);
      const encbyteCover = buffer.toString("base64");
      console.log(encbyteCover);
      setCoverImgSrc("data:image/jpg;base64," + encbyteCover);
    }
    if (byteArray1) {
      //   // const base64String = ByteArrayToBase64(byteArray1);
      //   var y = btoa(String.fromCharCode(new Uint8Array(byteCover)));
      //   setProfileImgSrc("data:image/png;base64," + y);

      const buffer = Buffer.from(byteProfile);
      const encbyteCover = buffer.toString("base64");
      setCoverImgSrc("data:image/jpg;base64," + encbyteCover);
    }
  }, [byteArray, byteArray1]);

  // --------------------- covert img logic cover ---------------------------

  // Cover byte aaray into img
  // useEffect(() => {}, [byteArray]);

  // Function to convert byte array to base64 string
  const byteArrayToBase64 = (byteArray) => {
    let binary = "";
    const bytes = new Uint8Array(byteArray);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  // protile  ----------------------------------------------------------------------------------------

  // Cover byte aaray into img
  // useEffect(() => {}, [byteArray1]);

  // Function to convert byte array to base64 string
  const ByteArrayToBase64 = (byteArray1) => {
    let binary = "";
    const bytes = new Uint8Array(byteArray1);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  console.log(getData);

  return (
    <>
      <div className="coach_form">
        {/* <CoachFormNavBar isNavBar={isNavBar} /> */}
        <Nav
          isOpen={isOpen}
          setOpen={setOpen}
          userInitial={userInitial}
          isSignedIn={isSignedIn}
          isSwitchBtn={isSwitchBtn}
          SwitchAccountBtn={SwitchAccountBtn}
        />
        <PhoneNavBar
          isOpenP={isOpenP}
          setOpenP={setOpenP}
          isSignedIn={isSignedIn}
          userInitial={userInitial}
        />
        <PopUpLoginP
          isOpenP={isOpenP}
          setOpenP={setOpenP}
          setIsSignedIn={setIsSignedIn}
          isSignedIn={isSignedIn}
          userInitial={userInitial}
        />
        <div className="form_section">
          {/* Cover photo DIV */}
          <div className="cover_photo">
            <img src={`${CoverImgSrc}`} className="cover_photo_img" alt="" />

            {/* PORFILE PHOTO DIV */}
            <div className="profile_input_box">
              <div className="profile_photo">
                <img className="profile_photo_img" alt="" />
              </div>
            </div>
          </div>
          <div className="from_header view_from_header ">
            <div className="view_coach_form_left">
              {/* Personal details */}
              <div className="input_info_box view_form_input_info_box">
                <div className="view_form_heading">Personal Details</div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">Name</p>
                  <p className="profile_title profile_title">{getData.name}</p>
                </div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">Tagline</p>
                  <p className="profile_title">{getData.tagline}</p>
                </div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">Email</p>
                  <p className="profile_title">{getData.email}</p>
                </div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">Phone Number</p>
                  <p className="profile_title">{getData.phone}</p>
                </div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">Location</p>
                  <p className="profile_title">{getData.location}</p>
                </div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">Gender</p>
                  <p className="profile_title">{getData.gender}</p>
                </div>
              </div>

              {/* Certificate */}
              {certifications && certifications.length > 0 ? (
                certifications.map((certifications) => (
                  <div key={certifications.name}>
                    <div className="input_info_box view_form_input_info_box">
                      <div className="view_form_heading">Certificates</div>
                      <div className="first_half view_form_first_half">
                        <p className="profile_lable">Certificate name</p>
                        <p className="profile_title profile_title">
                          {certifications.name}
                        </p>
                      </div>
                      <div className="first_half view_form_first_half">
                        <p className="profile_lable">certifications</p>
                        <p className="profile_title">
                          {certifications.issued_by}
                        </p>
                      </div>
                      <div className="first_half view_form_first_half">
                        <p className="profile_lable">Certificate issued on</p>
                        <p className="profile_title">
                          {certifications.issued_on}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className="view_coach_form_right">
              {/* About */}
              <div className="input_info_box view_form_input_info_box">
                <div className="view_form_heading">About Yourself</div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">About</p>
                  <p className="profile_title profile_title">{getData.about}</p>
                </div>
              </div>
              {/* you train*/}
              <div className="input_info_box view_form_input_info_box">
                <div className="view_form_heading">You Train</div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">Run</p>
                  <p className="profile_title profile_title">-</p>
                  <p className="profile_lable">Swim</p>
                  <p className="profile_title profile_title">-</p>
                  <p className="profile_lable">Cycling</p>
                  <p className="profile_title profile_title">-</p>
                  <p className="profile_lable">triathlon</p>
                  <p className="profile_title profile_title">-</p>
                </div>
              </div>
              {/* Video */}
              <div className="input_info_box view_form_input_info_box">
                <div className="view_form_heading">Your Video</div>
                <div className="first_half view_form_first_half">
                  <p className="profile_lable">Video</p>
                  <p className="profile_title profile_title">
                    <div className="video_div" style={{ margin: "0" }}>
                      <video id="video" controls></video>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="view_form_back_btn">
            <Link to="/">&#8592; Back</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewCoachForm;
