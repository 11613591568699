import React, { useState } from "react";
import EmailIcon from "../../../../assets/icons/drafts.png";
import PhoneIcon from "../../../../assets/icons/call.png";
import LocationIcon from "../../../../assets/icons/add_location.png";
import GenderIcon from "../../../../assets//icons/wc.png";
import "./css/PhoneformStep1.css";
function PhoneFormStep1(props) {
  const {
    email,
    setEmail,
    number,
    setNumber,
    location,
    setLocation,
    gender,
    setGender,
    isValidEmail,
    setIsValidEmail,
  } = props;

  // Email validation logic

  const handleEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
    // Validate email using regular expression
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsValidEmail(isValid);
  };

  //Phone Validation Logic
  const [isValidNumber, setIsValidNumber] = useState(true);
  const handleNumber = (event) => {
    const { value } = event.target;
    setNumber(value);
    // Validate email using regular expression
    const isValid = /^\d{10}$/g.test(value);
    setIsValidNumber(isValid);
  };

  return (
    <>
      <div className="phone_step1">
        <div>
          <div class="email">
            <label for="input" class="email">
              Email
              <sup style={{ color: "red", top: "-1.2rem", left: "2.3rem" }}>
                *
              </sup>
            </label>
            <div>
              <img src={EmailIcon} style={{ marginRight: "1rem" }} alt="" />
              <input
                type="email"
                placeholder=""
                name="email"
                value={email}
                class="input"
                onChange={handleEmail}
                required
              />
              {isValidEmail ? (
                <></>
              ) : (
                <p
                  style={{
                    color: "red",
                    textAlign: "right",
                    marginRight: "15%",
                  }}
                >
                  invalid email
                </p>
              )}
            </div>
          </div>

          <div class="number">
            <label for="input" class="text">
              Phone Number <sup style={{ color: "red" }}> *</sup>
            </label>
            <div>
              <img src={PhoneIcon} style={{ marginRight: "1rem" }} alt="" />
              <input
                type="text"
                placeholder=""
                name="number"
                class="input"
                value={number}
                maxLength={10}
                onChange={handleNumber}
                required
              />

              {isValidNumber ? (
                <></>
              ) : (
                <p
                  style={{
                    color: "red",
                    textAlign: "right",
                    marginRight: "15%",
                  }}
                >
                  invalid Number
                </p>
              )}
            </div>
          </div>

          <div class="location">
            <label for="input" class="text">
              Location<sup style={{ color: "red" }}> *</sup>
            </label>
            <div>
              <img src={LocationIcon} style={{ marginRight: "1rem" }} alt="" />
              <input
                type="text"
                placeholder=" Country, State"
                name="input"
                class="input"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </div>
          </div>

          {/* //Radio button */}
          <div class="mydict">
            <img src={GenderIcon} style={{ marginRight: "1rem" }} alt="" />

            <div>
              <label>
                <input
                  type="radio"
                  name="radio"
                  value={gender}
                  onChange={() => setGender("Male")}
                />
                <span>Male</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="radio"
                  value={gender}
                  onChange={() => setGender("Female")}
                />
                <span>Female</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="radio"
                  value={gender}
                  onChange={() => setGender("Trans")}
                />
                <span>Trans</span>
              </label>
              <sup style={{ color: "red" }}> *</sup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhoneFormStep1;
