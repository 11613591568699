import React, { useState } from "react";
import AddCertificate from "./AddCertificate";
import EmailIcon from "../../../assets/icons/drafts.png";
import PhoneIcon from "../../../assets/icons/call.png";
import LocationIcon from "../../../assets/icons/add_location.png";
import GenderIcon from "../../../assets//icons/wc.png";
import "./css/formHeaderLeft.css";

function FormHeaderLeft(props) {
  const {
    email,
    setEmail,
    number,
    setNumber,
    location,
    setLocation,
    gender,
    setGender,
    certificates,
    setCertificates,
  } = props;

  // Email validation logic
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleEmail = (event) => {
    const { value } = event.target;
    setEmail(value);

    // Validate email using regular expression
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsValidEmail(isValid);
  };

  //Phone Validation Logic
  const [isValidNumber, setIsValidNumber] = useState(true);
  const handleNumber = (event) => {
    const { value } = event.target;
    setNumber(value);
    // Validate email using regular expression
    const isValid = /^\d{10}$/g.test(value);
    setIsValidNumber(isValid);
  };

  return (
    <div className="form_header_left">
      <div class="email">
        <label htmlFor="input" class="email">
          Email
          <sup style={{ color: "red", top: "-1.2rem", left: "2.3rem" }}>*</sup>
        </label>

        <div className="input_line">
          <img src={EmailIcon} alt="" />
          <input
            type="email"
            placeholder=""
            name="email"
            value={email}
            class="input"
            onChange={handleEmail}
            required
          />
          {isValidEmail ? (
            <></>
          ) : (
            <p
              style={{
                color: "red",
                textAlign: "right",
                marginRight: "14%",
                fontWeight: "100",
                fontSize: ".7rem",
              }}
            >
              Invalid Email
            </p>
          )}
        </div>
      </div>

      <div class="number">
        <label htmlFor="input" class="text">
          Phone Number <sup style={{ color: "red" }}> *</sup>
        </label>
        <div>
          <img src={PhoneIcon} alt="" />
          <input
            type="text"
            placeholder=""
            name="number"
            class="input"
            maxLength={10}
            value={number}
            onChange={handleNumber}
            required
          />
          {isValidNumber ? (
            <></>
          ) : (
            <p
              style={{
                color: "red",
                textAlign: "right",
                marginRight: "14%",
                fontWeight: "100",
                fontSize: ".7rem",
              }}
            >
              Invalid Number
            </p>
          )}
        </div>
      </div>

      <div class="location">
        <label htmlFor="input" class="text">
          Location<sup style={{ color: "red" }}> *</sup>
        </label>
        <div>
          <img src={LocationIcon} alt="" />
          <input
            type="text"
            placeholder=" Country, State"
            name="input"
            class="input"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
      </div>

      {/* //Radio button */}
      <div class="mydict">
        <img src={GenderIcon} alt="" />

        <div>
          <label>
            <input
              type="radio"
              name="radio"
              value={gender}
              onChange={() => setGender("Male")}
            />
            <span>Male</span>
          </label>
          <label>
            <input
              type="radio"
              name="radio"
              value={gender}
              onChange={() => setGender("Female")}
            />
            <span>Female</span>
          </label>
          <label>
            <input
              type="radio"
              name="radio"
              value={gender}
              onChange={() => setGender("Trans")}
            />
            <span>Trans</span>
          </label>
          <sup style={{ color: "red" }}> *</sup>
        </div>
      </div>

      {/* certificate */}
      <div className="certificate_div">
        <AddCertificate
          certificates={certificates}
          setCertificates={setCertificates}
        />
      </div>
    </div>
  );
}

export default FormHeaderLeft;
