import React, { useEffect, useState } from "react";

import CoverPhoto from "./ViewAccountComponents/CoverPhoto.jsx";
import ProfileBox from "./ViewAccountComponents/ProfileBox.jsx";
import Setting from "./ViewAccountComponents/Setting.jsx";
import ConnectedDevice from "./ViewAccountComponents/ConnectedDevice.jsx";
import MyCoaching from "./ViewAccountComponents/MyCoaching.jsx";
import MyEvent from "./ViewAccountComponents/MyEvent.jsx";
import ViewAccFooter from "./ViewAccountComponents/ViewAccFooter.jsx";
import Aboutus from "./ViewAccountComponents/AbooutUs.jsx";
import Certificate from "./ViewAccountComponents/Certificate.jsx";
import YouTrain from "./ViewAccountComponents/YouTrain.jsx";
import Nav from "../NavBarM/DesktopComponents/Nav.jsx";
import PhoneNavBar from "../NavBarM/PhoneComponents/PhoneNavBar";
import PopUpLoginP from "../NavBarM/PhoneComponents/PopUpLoginP.jsx";
import { getUserDetails } from "./ViewAccountController";
import "./view-account.css";
function ViewAccount(props) {
  const {
    isOpen,
    setOpen,
    isOpenP,
    setOpenP,
    isSignedIn,
    userInitial,
    setIsSignedIn,
    getData,
    setData,
    isSwitchBtn,
    SwitchAccountBtn,
  } = props;

  // profile type coach or athlete
  const [profileType, setProfileType] = useState(null);

  // role get role from local storage
  const role = localStorage.getItem("role") ?? sessionStorage.getItem("role");
  console.log(getData);

  // true = coach
  // API call for get data and check if coach acc or athlete acc
  useEffect(() => {
    if (role === "Coach") {
      setProfileType(true);
    } else if (role === "Athlete") {
      setProfileType(false);
    }
    const fetchData = async () => {
      try {
        const res = await getUserDetails();
        setData(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        console.log("loading");
      }
    };
    fetchData();
  }, []);

  return (
    <div className="ViewAccount_section">
      <Nav
        isOpen={isOpen}
        setOpen={setOpen}
        userInitial={userInitial}
        isSignedIn={isSignedIn}
        isSwitchBtn={isSwitchBtn}
        SwitchAccountBtn={SwitchAccountBtn}
      />
      <PhoneNavBar
        isOpenP={isOpenP}
        setOpenP={setOpenP}
        isSignedIn={isSignedIn}
        userInitial={userInitial}
      />
      <PopUpLoginP
        isOpenP={isOpenP}
        setOpenP={setOpenP}
        setIsSignedIn={setIsSignedIn}
        isSignedIn={isSignedIn}
        userInitial={userInitial}
      />
      {getData.length != 0 && (
        <div className="viewAccount_box">
          <CoverPhoto />
          <ProfileBox getData={getData} setData={setData} />
          <Aboutus getData={getData} setData={setData} />
          {profileType ? <></> : <Setting getData={getData} />}
          {profileType ? <Certificate /> : <></>}
          {profileType ? <YouTrain /> : <></>}
          {profileType ? <></> : <ConnectedDevice />}
          {profileType ? <></> : <MyCoaching getData={getData} />}
          {profileType ? <></> : <MyEvent getData={getData} />}
          <ViewAccFooter profileType={profileType} />
        </div>
      )}
    </div>
  );
}

export default ViewAccount;
