import React from "react";

function MyEvent(props) {
  const { getData } = props;
  return (
    <div className="setting_box">
      <div className="heading_edit_box">
        <h2 className="heading">My Event</h2>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Event</p>
          <p className="profile_title">
            {getData.event_name === undefined ? (
              <span>&ndash;</span>
            ) : (
              `${getData.event_name}`
            )}
          </p>
        </div>
        <div className="second_half">
          <p className="profile_lable">Event date</p>
          <p className="profile_title">
            {getData.event_date === undefined ? (
              <span>&ndash;</span>
            ) : (
              `${getData.event_date}`
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default MyEvent;
