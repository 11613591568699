import { getUserDetails } from "./ViewAccount/ViewAccountController";
import { SwitchAccount } from "./Coachform/viewCoachForm/viewCoachFormController";
import { SetDataInlocalStorage } from "./Coachform/viewCoachForm/viewCoachFormController";
import { logout } from "../services/Header/Header";

const role = localStorage.getItem("role") ?? sessionStorage.getItem("role");
export async function SwitchAthleteToCoach(
  coachSignUpStatus,
  setCoachSignupStatus,
  verifiedClicked,
  RememberMe
) {
  if (coachSignUpStatus === "Verified" && verifiedClicked === true) {
    let ref_id;
    if (role === "Athlete") {
      const fetchProfileData = async () => {
        try {
          const res = await getUserDetails();
          if (res.data.profiles.length > 0) {
            ref_id = res.data.profiles[0].ref;
            if (ref_id) {
              // API code for Switch account
              const fetchData = async () => {
                try {
                  const res = await SwitchAccount(ref_id);
                  if (res) {
                    logout();
                    SetDataInlocalStorage(res, RememberMe);
                    const r =
                      localStorage.getItem("role") ??
                      sessionStorage.getItem("role");
                    alert("your are logined in as a " + r);
                    setCoachSignupStatus("View Profile");
                  }
                } catch (error) {
                  console.log(error);
                }
              };
              // calling switch account
              fetchData();
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          console.log("loading");
        }
      };
      //calling get user details
      fetchProfileData();
    }
  } else {
    return;
  }
}
