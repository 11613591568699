import React, { useEffect, useState } from "react";
import FormStep4 from "./FormStep4";
import RightArrow from "../../../../assets/icons/rightArrow.svg";

function FormStep3(props) {
  const {
    name,
    tagline,
    email,
    number,
    location,
    gender,
    aboutYourSelf,
    selectedDistancesRun,
    isRun,
    coverPhotoPath,
    profilePhotoPath,
    certificates,
  } = props;
  const [isSwim, setSwim] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [selectedDistancesSwim, setSelectedDistancesSwim] = useState([]);
  const [popper, setPopper] = useState(false);
  useEffect(() => {
    if (isSwim === "yes" || isSwim === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });

  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    if (selectedDistancesSwim.includes(value)) {
      setSelectedDistancesSwim(
        selectedDistancesSwim.filter((item) => item !== value)
      );
    } else {
      // Otherwise, add it to the array
      setSelectedDistancesSwim([...selectedDistancesSwim, value]);
    }
  };

  return (
    <div>
      {nextClicked && validation ? (
        <FormStep4
          name={name}
          tagline={tagline}
          email={email}
          number={number}
          location={location}
          gender={gender}
          aboutYourSelf={aboutYourSelf}
          selectedDistancesRun={selectedDistancesRun}
          selectedDistancesSwim={selectedDistancesSwim}
          isRun={isRun}
          coverPhotoPath={coverPhotoPath}
          profilePhotoPath={profilePhotoPath}
          certificates={certificates}
          isSwim={isSwim}
        />
      ) : (
        <div className="step2_div">
          <div className="question_1">
            <h4>Do you train athletes for Swimming ?</h4>
            <div className="yes_no_div swim">
              <label htmlFor="yes">
                <input
                  type="radio"
                  name="radio"
                  id="yes"
                  onChange={() => setSwim("yes")}
                />
                <span>Yes</span>
              </label>

              <label htmlFor="no">
                <input
                  type="radio"
                  name="radio"
                  id="no"
                  onChange={() => setSwim("no")}
                />
                <span>No</span>
              </label>
            </div>
          </div>

          {isSwim === "yes" ? (
            <>
              <div className="question_2">
                <h4>Select distances</h4>
                <div className="yes_no_div swim">
                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      value="1k"
                      onChange={() => handleCheckboxChange("1k")}
                    />
                    <span>1k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="1.5k"
                      onChange={() => handleCheckboxChange("1.5k")}
                    />
                    <span>1.5k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="1.9k"
                      onChange={() => handleCheckboxChange("1.9k")}
                    />
                    <span>1.9k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="3.8k"
                      onChange={() => handleCheckboxChange("3.8k")}
                    />
                    <span>3.8k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="5k"
                      onChange={() => handleCheckboxChange("5k")}
                    />
                    <span>5k</span>
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="next_steps_btn">
            {popper && (
              <div className="popper">
                <p>fill up the fileds</p>
              </div>
            )}

            <p
              className="next_btn"
              onClick={() => {
                if (validation === true) {
                  setNextClicked(true);
                  setPopper(false);
                } else {
                  if (validation === false) {
                    setPopper(true);
                  }
                }
              }}
            >
              3/5Next <img src={RightArrow} alt="" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormStep3;
