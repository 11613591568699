import React, { useState } from "react";
import AddIcon from "../../../assets/icons/add.svg";
import CancelIcon from "../../../assets/icons/cancel.svg";
import "./css/addcertificate.css";
function AddCertificate(props) {
  const { certificates = [], setCertificates } = props;
  const [isType, setType] = useState(false);

  const handleAddCertificate = () => {
    setCertificates([
      ...certificates,
      { name: "", issued_by: "", issued_on: "" },
    ]);
  };

  const handleRemoveCertificate = (index) => {
    const updatedCertificates = [...certificates];
    updatedCertificates.splice(index, 1);
    setCertificates(updatedCertificates);
  };

  const handleChange = (e, index, key) => {
    const updatedCertificates = [...certificates];
    updatedCertificates[index][key] = e.target.value;
    setCertificates(updatedCertificates);
  };

  return (
    <div className="add_certificate_section">
      <h4>Add your certificates that you have earned</h4>
      {/* <input
        type="text"
        placeholder="Certificate of"
        onChange={(e) => setname(e.target.value)}
      />
      <input
        type="text"
        placeholder="Certificate issued by"
        onChange={(e) => setissued_by(e.target.value)}
      />
      <input
        placeholder="Certificate issued on "
        class="textbox-n"
        type={`${isType ? "date" : "text"}`}
        onFocus={() => setType(true)}
        onChange={(e) => setissued_on(e.target.value)}
      /> */}
      {certificates.map((certificate, index) => (
        <div className="certificate_list" key={index}>
          <p
            className="revome_btn"
            onClick={() => handleRemoveCertificate(index)}
          >
            <img src={CancelIcon} alt="" />
          </p>
          <input
            type="text"
            placeholder="Certificate of"
            value={certificate.name}
            onChange={(e) => handleChange(e, index, "name")}
          />
          <input
            type="text"
            placeholder="Certificate issued by"
            value={certificate.issued_by}
            onChange={(e) => handleChange(e, index, "issued_by")}
          />
          <input
            type="Date"
            placeholder="Certificate issued on"
            value={certificate.issued_on}
            onChange={(e) => handleChange(e, index, "issued_on")}
          />
        </div>
      ))}
      <div className="add_div">
        <p className="add_btn" onClick={handleAddCertificate}>
          Add Certificates <img src={AddIcon} alt="" />
        </p>
      </div>
    </div>
  );
}

export default AddCertificate;
