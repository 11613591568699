import React, { useEffect, useState } from "react";
function Aboutus(props) {
  const { getData, setData } = props;
  const [showMore, setShowMore] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [about, setAbout] = useState(getData.about);

  // check if data is empty
  useEffect(() => {
    if (getData.about === undefined || null) {
      setAbout("");
    }
  });

  // about us data
  const text = `${about}`;

  // show more btn logic
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  function handleSave() {
    setEdit(false);
  }
  return (
    <div className="setting_box">
      <div className="heading_edit_box">
        <h2 className="heading">About</h2>
        {isEdit ? (
          <p className="edit_button" onClick={handleSave}>
            Save
          </p>
        ) : (
          <p className="edit_button" onClick={() => setEdit(true)}>
            Edit
          </p>
        )}
      </div>
      <div className="input_info_box">
        <div className="about_box">
          {isEdit ? (
            <input
              type="text"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            />
          ) : (
            <p
              className="about_title"
              style={{ color: "rgba(2, 48, 71, 0.9)", fontSize: "1.2rem" }}
            >
              {showMore ? text : `${text.substring(0, 80)}...`}
              <span
                style={{ fontSize: ".8rem", cursor: "pointer", color: "blue" }}
                onClick={handleShowMore}
              >
                {showMore ? " show less" : "show more"}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
