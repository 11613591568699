import React, { useState } from "react";
import show from "../../../assets/icons/OpenEye.svg";
import nonShow from "../../../assets/icons/CloseEye.svg";
import { SubmitData } from "../navController";
import { Button } from "rsuite";
import "../css/nav.css";
import "../css/mediaQuery.css";
import ForgorPasswordPopUp from "../ForgopasswordComponents/ForgotPasswordPopUp";
import "rsuite/dist/styles/rsuite-default.min.css";
function PopUpLogin(props) {
  const {
    isOpen,
    setOpen,
    setIsSignedIn,
    setRememberMe,
    RememberMe,
    isSignUp,
    setSignUp,
  } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [isCancel, setCancel] = useState(false);
  const [load, setLoad] = useState(false);
  // Hide Input
  const [inputE, setInputE] = useState(false);
  const [inputP, setInputP] = useState(false);

  // close logic for popUpLogin (when the transparent div is clicked IsOpen is false)
  function handleClose() {
    setOpen(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);
    const req = await SubmitData(email, password, RememberMe, e);
    if (req === true) {
      setLoad(false);
      setIsSignedIn(true);
      setOpen(false);
    } else if (req === false) {
      setLoad(false);
    }
  }

  return (
    <>
      <div className={`slider ${isOpen ? "visible" : ""}`}>
        {!isForgotPassword ? (
          <>
            <form onSubmit={handleSubmit} className="form">
              <div className="desktop_input_div">
                <input
                  onMouseEnter={() => setInputE(!inputE)}
                  onMouseOut={() => setInputE(false)}
                  type="email"
                  name="email"
                  placeholder={`  ${inputE ? "" : "Email"}`}
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  onMouseEnter={() => setInputP(!inputP)}
                  onMouseOut={() => setInputP(false)}
                  type={showPassword ? "text" : "password"}
                  placeholder={`  ${inputP ? "" : "Password"}`}
                  required
                  minLength="8"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label
                  className="show"
                  htmlFor=""
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <img src={showPassword ? nonShow : show} alt="" />
                </label>
              </div>
              <div className="checkbox_div">
                <div>
                  <input
                    type="checkbox"
                    onChange={() => setRememberMe(!RememberMe)}
                  />
                  <span>Remember me</span>
                </div>
                <div>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setForgotPassword(!isForgotPassword);
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="signup_btn">
                <p
                  onClick={() => {
                    setSignUp(!isSignUp);
                    setOpen(false);
                  }}
                >
                  Don’t have a account? <span>Sign up</span>
                </p>
              </div>
              <div className="submit_button">
                {load ? (
                  <button>
                    {" "}
                    <Button
                      loading
                      className="submit_box"
                      style={{ backgroundColor: "#fdc027" }}
                    ></Button>
                  </button>
                ) : (
                  <button>Sign in</button>
                )}
              </div>
            </form>
          </>
        ) : (
          <div className="forgotPassword_desktop">
            <ForgorPasswordPopUp
              setForgotPassword={setForgotPassword}
              isCancel={isCancel}
              isOpen={isOpen}
              setOpen={setOpen}
            />
          </div>
        )}

        {isOpen && (
          <div onClick={handleClose} className="desktop_popup_background"></div>
        )}
      </div>
      {/*------------------- SIGN UP POPUP -----------------------*/}
    </>
  );
}

export default PopUpLogin;
