import { useEffect, useState } from "react";
import Logo from "../../../common/Logo";
import { Link } from "react-router-dom";
import "../css/nav.css";
import { logout } from "../../../services/Header/Header";
import { useLocation } from "react-router-dom";

function Nav(props) {
  const {
    isOpen,
    setOpen,
    userInitial,
    isSignedIn,
    isSwitchBtn,
    SwitchAccountBtn,
  } = props;
  const [showLi, setShowLi] = useState(true);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isViewAccount, setViewAccount] = useState(false);
  const UserEmail =
    localStorage.getItem("email") ?? sessionStorage.getItem("email");
  const UserName =
    localStorage.getItem("name") ?? sessionStorage.getItem("name");
  const role = localStorage.getItem("role") ?? sessionStorage.getItem("role");

  function handleClick() {
    setOpen(!isOpen);
  }
  function handleProfileClose() {
    setProfileOpen(false);
  }
  function handleProfileClick() {
    setProfileOpen(!isProfileOpen);
  }

  function handleClickScrollCoach() {
    const element = document.getElementById("coach");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleClickScrollAthele() {
    const element = document.getElementById("athlete");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleClickScrollSupport() {
    const element = document.getElementById("footer");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleClickScrollAboutus() {
    const element = document.getElementById("about");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const location = useLocation();

  useEffect(() => {
    // Check if the user is on the specified URL
    if (
      location.pathname === "/MyBilling" ||
      location.pathname === "/ViewAccount" ||
      location.pathname === "/CoachForm" ||
      location.pathname === "/MyCoachingPlans"
    ) {
      setShowLi(false);
    } else {
      setShowLi(true);
    }
  }, [location]);

  return (
    <>
      <div className="navbar">
        <div className="navbar_items">
          <div className="navbar_logo">
            <Logo />
          </div>
          {showLi && (
            <div className="navbar_li">
              <li onClick={handleClickScrollCoach}>Become a Coach</li>
              <li onClick={handleClickScrollAthele}>For an Athlete</li>
              <li onClick={handleClickScrollAboutus}>About us</li>
              <li onClick={handleClickScrollSupport}>Support</li>
            </div>
          )}

          {isSignedIn && (
            <div className="profile_box_dekstop">
              <div className="Profile_logo" onClick={handleProfileClick}>
                <span>{userInitial}</span>
              </div>
              <div
                className={`profile_dropdown ${
                  isProfileOpen ? "visible_profile" : ""
                }`}
              >
                <div className="logout_button">
                  <button onClick={logout}>Logout</button>
                </div>
                <div className="profile">
                  <div className="signedin_initial_box">
                    <div className="signedin_initial">
                      <span>{userInitial}</span>
                    </div>
                  </div>
                  <div className="profile_info_box">
                    <div className="profile_info">
                      <div className="profile_info_name">
                        <h3>{UserName}</h3>
                        <p>{UserEmail}</p>
                      </div>

                      {!isViewAccount ? (
                        <div className="profile_info_links">
                          <Link to="/ViewAccount">View Account</Link>
                          <Link to="/MyBilling">My Billing</Link>
                          {isSwitchBtn && (
                            <a onClick={() => SwitchAccountBtn()}>
                              Switch Account
                            </a>
                          )}
                          {role === "Coach" && (
                            <Link to="/MyCoachingPlans">My Coaching Plans</Link>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isSignedIn && (
            <div className="sign_button">
              <button onClick={handleClick}>SIGN IN</button>
            </div>
          )}
        </div>
      </div>

      {isProfileOpen && (
        <div
          onClick={handleProfileClose}
          className="desktop_popup_profile_background"
        ></div>
      )}
    </>
  );
}

export default Nav;
