import React, { useEffect } from "react";
import coverphotoURL from "../../../assets/img/coverphoto.png";
import profilePhotoURL from "../../../assets/img/profilephoto.png";
import InputBox from "../../Coachform/formcomponents/InputBox";
import { getCoverImg } from "../ViewAccountController";

function CoverPhoto() {
  //API call for get cover img
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCoverImg();
        console.log(res);
      } catch (error) {
        console.log(error);
      } finally {
        console.log("loading");
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="cover_photo">
        <img
          className="cover_photo_img"
          id="photo"
          src={coverphotoURL}
          alt="Uploaded"
        />
        <input
          id="file-input"
          type="file"
          accept="image/*"
          style={{ visibility: "hidden" }}
        />
        <label className="cover_photo_input" htmlFor="file-input">
          Edit
        </label>
        {/* PORFILE PHOTO DIV */}
        <div className="profile_input_box">
          <div className="profile_photo">
            <img className="profile_photo_img" src={profilePhotoURL} alt="" />
            <input
              type="file"
              accept="image/*"
              name=""
              placeholder="+"
              id="profilePhoto"
              style={{ visibility: "hidden" }}
            />
            <label
              className="profile_photo_input"
              htmlFor="profilePhoto"
            ></label>
          </div>
        </div>
      </div>
    </>
  );
}
export default CoverPhoto;
