import React, { useState, useEffect } from "react";
import { SwitchAthleteToCoach } from "./HomeController";
import { AppConstants } from "../app.constant";
import { Header } from "../services/Header/Header";
import styled from "styled-components";
import axios from "axios";
import background1 from "../assets/img/cover_photo_login_page.jpg";
import FooterSection from "./HomePage/Footer/FooterPage/FooterSection";
import Nav from "./NavBarM/DesktopComponents/Nav";
import PhoneNavBar from "./NavBarM/PhoneComponents/PhoneNavBar";
import PopUpLogin from "./NavBarM/DesktopComponents/PopUpLogin";
import PopUpLoginP from "./NavBarM/PhoneComponents/PopUpLoginP";
import ForgorPasswordPopUp from "./NavBarM/ForgopasswordComponents/ForgotPasswordPopUp";
import AboutUs from "./HomePage/AboutUs/AboutUs";
import Coach from "./HomePage/coach/Coach";
// import Athele from "./Athlete/Athlete";
import "./NavBarM/css/mediaQuery.css";
import "./NavBarM/css/popup.css";
import SignUpPopUp from "./NavBarM/DesktopComponents/signup/SignUpPopUp";
import { logout } from "../services/Header/Header";

export function Home(props) {
  const {
    isOpen, //open dekstop sign in popup
    setOpen,
    isOpenP, //open phone popup
    setOpenP,
    isSignedIn, // checks if user is signed in or not
    setIsSignedIn,
    userInitial,
    isSwitchBtn,
    SwitchAccountBtn,
    coachSignUpStatus,
    setCoachSignupStatus,
    RememberMe,
    setRememberMe,
    isSignInOpen,
    setSignInOpen,
  } = props;

  //check if clicked  SIGN UP
  const [isSignUp, setSignUp] = useState(false);
  // FORGET PASSWORD
  const [isForgotPassword, setForgotPassword] = useState(false);

  // Verified button clicked in coach section
  const [verifiedClicked, setVerifiedClicked] = useState();

  // just for testing
  SwitchAthleteToCoach(
    coachSignUpStatus,
    setCoachSignupStatus,
    verifiedClicked,
    RememberMe
  );

  //API call for coach signup status
  //1,2,6, => view form / null,3,5 => get Stated page / 4=> form editable
  useEffect(() => {
    const role = localStorage.getItem("role") ?? sessionStorage.getItem("role");
    async function getoachStatus() {
      const header = Header();
      return axios
        .get(AppConstants.GET_COACH_SIGNUP_STATUS, {
          headers: header,
        })
        .then((res) => {
          console.log(res);
          if (res.data.cs_status === undefined || null) {
            setCoachSignupStatus("null");
          } else {
            if (res.data.cs_status === 1) {
              setCoachSignupStatus("Pending verfication");
            } else if (res.data.cs_status === 2 && role === "Athlete") {
              setCoachSignupStatus("Verified");
            } else if (res.data.cs_status === 2 && role === "Coach") {
              setCoachSignupStatus("View Profile");
            } else if (res.data.cs_status === 4) {
              setCoachSignupStatus("Need Action");
            } else if (res.data.cs_status === 6) {
              setCoachSignupStatus("View Profile");
            } else if (res.data.cs_status === 3) {
              setCoachSignupStatus("3");
            } else if (res.data.cs_status === 5) {
              setCoachSignupStatus("5");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.message);
        });
    }

    if (isSignedIn) {
      getoachStatus();
    }
  }, [isSignedIn]);

  return (
    <>
      <MiddlePageView>
        <MainContainer>
          <BackgroundImage id="bg">
            {isForgotPassword && (
              <div className="forgotpassword_popup_box">
                <ForgorPasswordPopUp
                  setForgotPassword={setForgotPassword}
                  isOpen={isOpen}
                  setOpen={setOpen}
                />
              </div>
            )}

            <Nav
              isOpen={isOpen}
              setOpen={setOpen}
              userInitial={userInitial}
              isSignedIn={isSignedIn}
              isSwitchBtn={isSwitchBtn}
              SwitchAccountBtn={SwitchAccountBtn}
            />
            <PhoneNavBar
              isOpenP={isOpenP}
              setOpenP={setOpenP}
              isSignedIn={isSignedIn}
              userInitial={userInitial}
            />
            {isSignUp ? (
              <SignUpPopUp
                setSignUp={setSignUp}
                isSignUp={isSignUp}
                setOpen={setOpen}
              />
            ) : (
              <PopUpLogin
                isOpen={isOpen}
                setOpen={setOpen}
                setIsSignedIn={setIsSignedIn}
                RememberMe={RememberMe}
                setRememberMe={setRememberMe}
                isSignUp={isSignUp}
                setSignUp={setSignUp}
              />
            )}

            <PopUpLoginP
              isOpenP={isOpenP}
              setOpenP={setOpenP}
              setIsSignedIn={setIsSignedIn}
              isSignedIn={isSignedIn}
              userInitial={userInitial}
              RememberMe={RememberMe}
              setRememberMe={setRememberMe}
              isForgotPassword={isForgotPassword}
              setForgotPassword={setForgotPassword}
              isSignUp={isSignUp}
              setSignUp={setSignUp}
              isSignInOpen={isSignInOpen}
              setSignInOpen={setSignInOpen}
              isSwitchBtn={isSwitchBtn}
              SwitchAccountBtn={SwitchAccountBtn}
            />
            <div className="header_text_box">
              <div id="heading" className="header_text">
                TRACKOFIT
                <span id="para">For the Athletes by the coaches</span>
              </div>
            </div>
          </BackgroundImage>

          <Coach
            isSignedIn={isSignedIn}
            setOpen={setOpen}
            setSignInOpen={setSignInOpen}
            coachSignUpStatus={coachSignUpStatus}
            setVerifiedClicked={setVerifiedClicked}
            verifiedClicked={verifiedClicked}
          />
          {/* <Athele /> */}
          <AboutUs />
          <MiddleView>
            <FooterSection
              setOpen={setOpen}
              isOpen={isOpen}
              setOpenP={setOpenP}
              isOpenP={isOpenP}
            />
          </MiddleView>
        </MainContainer>
      </MiddlePageView>
    </>
  );
}

const MainContainer = styled.div`
  height: 60px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: grid;
  // font-family: roboto-condensed !important;
  align-items: center;
`;

const BackgroundImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${background1});
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100%;
`;

const MiddleView = styled.div``;

const MiddlePageView = styled.div`
  height: 100vh;
`;
