import React, { useState } from "react";
import RightArrow from "../../../../assets/icons/rightArrow.svg";
import PhoneFormStep4 from "./PhoneFormStep4";
function PhoneFormStep3(props) {
  const { name, tagline, email, number, location, gender } = props;
  const [nextClicked, setNextClicked] = useState(false);
  const [aboutYourSelf, setAboutYourSelf] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    const videoElement = document.getElementById("video");

    if (file && file.type.startsWith("video/")) {
      // If the selected file is a video
      const fileURL = URL.createObjectURL(file); // Create a URL for the selected file
      videoElement.src = fileURL; // Set the source of the video element to the file URL
    } else {
    }
  };
  return (
    <div>
      {nextClicked ? (
        <>
          <PhoneFormStep4
            name={name}
            tagline={tagline}
            email={email}
            number={number}
            location={location}
            gender={gender}
            aboutYourSelf={aboutYourSelf}
          />
        </>
      ) : (
        <>
          <div className="phone_form_step3" style={{ padding: "1rem" }}>
            <div className="about_div">
              <h4>
                Write something about yourself so that your athletes can
                understand you well.{" "}
              </h4>
              <div class="yourself">
                <label for="input" class="text">
                  About Yourself:
                </label>

                <textarea
                  id="w3review"
                  name="w3review"
                  rows="10"
                  class="input"
                  onChange={(e) => setAboutYourSelf(e.target.value)}
                >
                  Hello i am...
                </textarea>
              </div>
            </div>
            <div className="video_div">
              <h4>You can upload a small 1min video talking about yourself.</h4>
              <video id="video" controls></video>
              <input
                id="file-input"
                type="file"
                accept="video/*"
                onChange={handleFileChange}
              />
            </div>
            <div className="Phone_next_steps_btn">
              <p
                className="next_btn"
                onClick={() => {
                  setNextClicked(!nextClicked);
                }}
              >
                3/7Next <img src={RightArrow} alt="" />
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PhoneFormStep3;
