import React, { useState } from "react";
import CoachingPlanCard from "./coachingPlansComponents/CoachingPlanCard";
import AddPopup from "./coachingPlansComponents/AddPopup";
import Nav from "../NavBarM/DesktopComponents/Nav";
import PhoneNavBar from "../NavBarM/PhoneComponents/PhoneNavBar";
import PopUpLoginP from "../NavBarM/PhoneComponents/PopUpLoginP";
import AddBtn from "../../assets/icons/add_btn_card.svg";
import "./css/coaching_plan.css";
import { DeleteCoahingPlan } from "./MyCoachingPlansController";

function MyCoachingPlans(props) {
  const {
    isOpen,
    setOpen,
    isOpenP,
    setOpenP,
    isSignedIn,
    userInitial,
    setIsSignedIn,
    isSwitchBtn,
    SwitchAccountBtn,
    isSignInOpen,
    setSignInOpen,
  } = props;

  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [planId, setPlanId] = useState("");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [planCharge, setPlanCharge] = useState("");
  function handleEdit(plan_id, plan_name, description, plan_charge) {
    setAdd(true);
    setEdit(true);
    setPlanId(plan_id);

    setName(plan_name);
    setDescription(description);
    setPlanCharge(plan_charge);
  }

  function handleRevomeId(plan_id) {
    setPlanId(plan_id);
  }

  return (
    <div className="my_coaching_plan_section">
      <Nav
        isOpen={isOpen}
        setOpen={setOpen}
        userInitial={userInitial}
        isSignedIn={isSignedIn}
        isSwitchBtn={isSwitchBtn}
        SwitchAccountBtn={SwitchAccountBtn}
      />
      <PhoneNavBar
        isOpenP={isOpenP}
        setOpenP={setOpenP}
        isSignedIn={isSignedIn}
        userInitial={userInitial}
      />
      <PopUpLoginP
        isOpenP={isOpenP}
        setOpenP={setOpenP}
        setIsSignedIn={setIsSignedIn}
        isSignedIn={isSignedIn}
        userInitial={userInitial}
        isSignInOpen={isSignInOpen}
        setSignInOpen={setSignInOpen}
      />
      {add && (
        <div className="blur Add_popup_div">
          <AddPopup
            setAdd={setAdd}
            edit={edit}
            setEdit={setEdit}
            planId={planId}
            name={name}
            description={description}
            planCharge={planCharge}
          />
        </div>
      )}

      <div className="center_90_width">
        <div className="heading">
          <h2>Add Your Perfect Plan</h2>
          <p>
            Here is your complimentary plan, available to just 5 users. You can
            add your plans here, which will then be displayed to the athletes.
          </p>
        </div>

        <div className="card_div">
          <CoachingPlanCard
            handleEdit={handleEdit}
            add={add}
            handleRevomeId={handleRevomeId}
            planId={planId}
          />
          <div className="add_card_plan">
            <div className="add_btn" onClick={() => setAdd(true)}>
              <img src={AddBtn} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCoachingPlans;
