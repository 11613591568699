import axios from "axios";
import { Header } from "../../services/Header//Header";
import { AppConstants } from "../../app.constant";
import { getToke } from "../../services/Header/Header";

export async function SubmitCoverPhoto(file) {
  const token = getToke();
  const headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(AppConstants.COACHFORM_PHOTO_UPLOAD_API + "/1", formData, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return { path: res.data.path, success: true };
      } else {
        return { path: null, success: false };
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function SubmitProfilePhoto(file) {
  const token = getToke();
  const headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(AppConstants.COACHFORM_PHOTO_UPLOAD_API + "/2", formData, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.status_code === 1) {
        return { path: res.data.path, success: true };
      } else {
        return { path: null, success: false };
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function SubmitVideo(file) {
  const token = getToke();
  const headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post(AppConstants.COACHFORM_PHOTO_UPLOAD_API + "/3", formData, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
      if (res.data.status_code === 1) {
        return { path: res.data.path, success: true };
      } else {
        return { path: null, success: false };
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function SubmitCoachForm(
  name,
  tagline,
  email,
  number,
  location,
  gender,
  aboutYourSelf,
  selectedDistancesRun,
  selectedDistancesSwim,
  selectedDistancesCycle,
  selectedDistancesTriathlon,
  coverPhotoPath,
  profilePhotoPath,
  certificates,
  TAC
) {
  const header = Header();
  console.log(profilePhotoPath);
  console.log(coverPhotoPath);
  return axios
    .post(
      AppConstants.COACHFORM_FORM_SUBMIT_API,
      {
        name: name,
        tagline: tagline,
        email: email,
        phone: number,
        location: location,
        gender: gender,
        about: aboutYourSelf,
        services_offered: {
          persionalized: 1, // 1 or 0 1 for yes, 0 for no
          standard: 1, // 1 or 0 1 for yes, 0 for no
        },
        certifications: certificates,
        coaches: {
          run: selectedDistancesRun,
          bike: selectedDistancesCycle,
          swim: selectedDistancesSwim,
          triathlon: selectedDistancesTriathlon,
        },
        profile_photo: profilePhotoPath,
        cover_photo: coverPhotoPath, //coverPhotoPath,
        video: null,
        status: null, // 0 pending verification  , 1 verified, 2 rejected,
        send_marketing: 1,
        accept_tnc: TAC,
      },
      {
        headers: header,
      }
    )

    .then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        return true;
      } else if (response.data.status_code === 2) {
        console.log("error");
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
