import React from "react";
import Logo from "../../../../common/Logo";
import playStore from "../../../../assets/img/play-store.png";
import appStore from "../../../../assets/img/apple-store.png";
import "./footer.css";

export default function DesktopFooter() {
  function handleClickScrollCoach() {
    const element = document.getElementById("coach");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  function handleClickScrollAbout() {
    const element = document.getElementById("about");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <div className="footer_section" id="footer">
      <div className="footer_logo_div">
        <Logo />
        <div className="footer_download_img">
          <a
            href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1"
            className="play_store_a"
          >
            <img className="play_store_img" src={playStore} alt="playStore" />
          </a>
          <a
            href="https://apps.apple.com/in/app/trackofit/id6469041232"
            className="app_store_a"
          >
            <img className="app_store_img" src={appStore} alt="appStore" />
          </a>
        </div>
      </div>
      <div className="footer_athletes_div">
        <h5>For An Athletes</h5>
        <a>Sign Up</a>
      </div>
      <div className="footer_coaches_div">
        <h5>Become a coach</h5>
        <a onClick={handleClickScrollCoach}>Sign Up</a>
      </div>
      <div className="footer_support_div">
        <h5>Support</h5>
        <a href="mailto:support@trackofit.com">Help</a>
        <a href="mailto:support@trackofit.com">Contact Us</a>
        <a href="https://www.trackofit.com/privacypolicy.html">
          Privacy Policy
        </a>
      </div>
      <div className="footer_company_div">
        <h5>Company</h5>
        <a onClick={handleClickScrollAbout}>About</a>
      </div>
    </div>
  );
}
