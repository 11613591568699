import React from "react";

function MyCoaching(props) {
  const { getData } = props;
  return (
    <div className="setting_box">
      <div className="heading_edit_box">
        <h2 className="heading">My Coaching</h2>
        <p style={{ color: "rgba(2, 48, 71, 0.9)" }}>Long Format Race </p>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Training With</p>
          <p className="profile_title">&ndash; </p>
        </div>
        <div className="second_half">
          <p className="profile_lable">Fees</p>
          <p className="profile_title" style={{ color: "#04B801" }}>
            &ndash;
          </p>
        </div>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Training Since</p>
          <p className="profile_title">
            {getData.athlete_type === undefined ? (
              <span>&ndash;</span>
            ) : (
              `${getData.active_since}`
            )}
          </p>
        </div>
        <div className="second_half">
          <p className="profile_lable">Coaching Status</p>
          <p className="profile_title">&ndash; </p>
        </div>
      </div>
    </div>
  );
}

export default MyCoaching;
