import React, { useEffect, useState } from "react";
import PhoneFormStep7 from "./PhoneFormStep7";
import RightArrow from "../../.././../assets/icons/rightArrow.svg";

function PhoneFormStep6(props) {
  const {
    name,
    tagline,
    email,
    number,
    location,
    gender,
    aboutYourSelf,
    selectedDistancesRuning,
    selectedDistancesSwim,
  } = props;
  const [isYes, setYes] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [popper, setPopper] = useState(false);
  const [selectedDistancesCycle, setSelectedDistancesCycle] = useState([]);
  useEffect(() => {
    if (isYes === "yes" || isYes === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    if (selectedDistancesCycle.includes(value)) {
      setSelectedDistancesCycle(
        selectedDistancesCycle.filter((item) => item !== value)
      );
    } else {
      // Otherwise, add it to the array
      setSelectedDistancesCycle([...selectedDistancesCycle, value]);
    }
  };

  return (
    <div>
      {nextClicked && validation ? (
        <PhoneFormStep7
          name={name}
          tagline={tagline}
          email={email}
          number={number}
          location={location}
          gender={gender}
          aboutYourSelf={aboutYourSelf}
          selectedDistancesRuning={selectedDistancesRuning}
          selectedDistancesSwim={selectedDistancesSwim}
          selectedDistancesCycle={selectedDistancesCycle}
        />
      ) : (
        <div className="phone_form_step6" style={{ marginTop: "4rem" }}>
          <div className="step2_div">
            <div className="question_1 ">
              <h4>Do you train athletes for Cycling ?</h4>
              <div className="yes_no_div cycle">
                <label>
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setYes("yes")}
                  />
                  <span>Yes</span>
                </label>

                <label>
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setYes("no")}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>

            {isYes === "yes" ? (
              <>
                <div className="question_2">
                  <h4>Select distances</h4>
                  <div className="yes_no_div cycle">
                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        onChange={() => handleCheckboxChange("40k")}
                      />
                      <span>40k</span>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        id=""
                        onChange={() => handleCheckboxChange("90k")}
                      />
                      <span>90k</span>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        id=""
                        onChange={() => handleCheckboxChange("100k")}
                      />
                      <span>100k</span>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        id=""
                        onChange={() => handleCheckboxChange("160k")}
                      />
                      <span>160k</span>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        id=""
                        onChange={() => handleCheckboxChange("200k")}
                      />
                      <span>200k</span>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="radio"
                        id=""
                        onChange={() => handleCheckboxChange("Time Trail")}
                      />
                      <span>Time Trail</span>
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="Phone_next_steps_btn">
              {popper && (
                <div className="popper">
                  <p>Fill up the fileds</p>
                </div>
              )}
              <p
                className="next_btn"
                onClick={() => {
                  if (validation === true) {
                    setNextClicked(true);
                    setPopper(false);
                  } else {
                    if (validation === false) {
                      setPopper(true);
                    }
                  }
                }}
              >
                6/7Next <img src={RightArrow} alt="" />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default PhoneFormStep6;
