import axios from "axios";
import { AppConstants } from "../../app.constant";
import { Header } from "../../services/Header/Header.js";

export async function getUserDetails() {
  const header = Header();
  return axios
    .get(AppConstants.GET_USER_DETAILS, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function getCoverImg() {
  const header = Header();
  return axios
    .get(AppConstants.GET_COVER_IMG, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function getProfileImg() {
  const header = Header();
  return axios
    .get(AppConstants.GET_PROFILE_IMG, {
      headers: header,
    })
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export async function EditViewAccount(name, mobile, athlete_Type, dob, gender) {
  const header = Header();
  localStorage.setItem("name", name);
  return axios
    .post(
      AppConstants.VIEW_ACCOUNT_EDIT,
      {
        name: name,
        gender: gender,
        dob: dob,
        mobile: mobile,
        athlete_type: athlete_Type,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.status_code === 1) {
        console.log(res);
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}
