import axios from "axios";
import { AppConstants } from "./../../app.constant";
import jwt_decode from "jwt-decode";

export function getToke(){
  const token = getJWTToken();
  return token
}

export function Header() {
  const token = getJWTToken();
  if (token) {
    return {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    };
  } else {
    return {
      "Content-type": "application/json"
    };
  }
}

//when you are calling a api then call it
export function getJWTToken() {
  const token = localStorage.getItem(AppConstants.JWT_TOKEN) ?? sessionStorage.getItem(AppConstants.JWT_TOKEN);
  if (token === "null") {
    alert("Session Expired. Login again.");
    logout();
    return "";
  } else {
    const date = new Date();
    const currentTimeInSeconds = date.getSeconds();
    const decodedToken = jwt_decode(token);
    var expiryTimeInSeconds = decodedToken["exp"];
    const isValid = expiryTimeInSeconds > currentTimeInSeconds;
    if (isValid){
      return token;
    }else{
      alert("Session Expired. Login again.");
    logout();
    return "";
    }
  }
}

export function logout() {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/";
}




export async function addPosts(email, password, RememberMe)  {
  return axios
    .post(AppConstants.REST_API_LOGIN_ENDPOINT, {
      email: email,
      password: password,
    })
    .then((response) => {
    
      if (response.status === 200 && response.data["token"] !== undefined) {
        const token = response.data["token"];
        const decodedToken = jwt_decode(token);
        var userEmail = decodedToken["email"];
        var userName = decodedToken["name"];
        var userRole = decodedToken["role"];
        var ref_id = decodedToken["id"];
        localStorage.setItem(AppConstants.JWT_TOKEN, token);
        localStorage.setItem("email", userEmail);
        localStorage.setItem("name", userName.trim());
        localStorage.setItem("role", userRole);
        localStorage.setItem("ref_id", ref_id);
        localStorage.setItem("remember_me", RememberMe);
        return true;
      } else if (response.status === 200) {
        if (response.data["status_code"] === 2) {
          alert(response.data["message"]);
          return false;
        }
      } else {
        alert("An unexpected error occurred");
        throw new Error("Unexpected status code");
      }
    })
    .catch((error) => {
      console.log(error.message);
      alert(error);
      throw error;
    });
}



export async function addPostsNoRememberMe(email, password)  {
  return axios
    .post(AppConstants.REST_API_LOGIN_ENDPOINT, {
      email: email,
      password: password,
    })
    .then((response) => {
      
      if (response.status === 200 && response.data["token"] !== undefined) {
        const token = response.data["token"];
        const decodedToken = jwt_decode(token);
        var userEmail = decodedToken["email"];
        var userName = decodedToken["name"];
        var userRole = decodedToken["role"];
        var ref_id = decodedToken["id"];
        sessionStorage.setItem(AppConstants.JWT_TOKEN, token);
        sessionStorage.setItem("email", userEmail);
        sessionStorage.setItem("name", userName);
        sessionStorage.setItem("role", userRole);
        sessionStorage.setItem("ref_id", ref_id);
        return true;
      } else if (response.status === 200) {
        if (response.data["status_code"] === 2) {
          alert(response.data["message"]);
          return false;
        }
      } else {
        alert("An unexpected error occurred");
        throw new Error("Unexpected status code");
      }
    })
    .catch((error) => {
      console.log(error.message);
      alert(error);
      throw error;
    });
}
