import React, { useEffect, useState } from "react";
import FormStep3 from "./FormStep3";
import "./fromstep.css";
import RightArrow from "../../../../assets/icons/rightArrow.svg";
function FormStep2(props) {
  const {
    name,
    tagline,
    email,
    number,
    location,
    gender,
    aboutYourSelf,
    coverPhotoPath,
    profilePhotoPath,
    certificates,
  } = props;
  const [validation, setValidation] = useState(false);
  const [isRun, setRun] = useState("");
  const [popper, setPopper] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [selectedDistancesRun, setSelectedDistancesRun] = useState([]);
  useEffect(() => {
    if (isRun === "yes" || isRun === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });

  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    if (selectedDistancesRun.includes(value)) {
      setSelectedDistancesRun(
        selectedDistancesRun.filter((item) => item !== value)
      );
    } else {
      // Otherwise, add it to the array
      setSelectedDistancesRun([...selectedDistancesRun, value]);
    }
  };

  return (
    <div className="form_step2 ">
      {nextClicked && validation ? (
        <FormStep3
          name={name}
          tagline={tagline}
          email={email}
          number={number}
          location={location}
          gender={gender}
          aboutYourSelf={aboutYourSelf}
          selectedDistancesRun={selectedDistancesRun}
          isRun={isRun}
          coverPhotoPath={coverPhotoPath}
          profilePhotoPath={profilePhotoPath}
          certificates={certificates}
        />
      ) : (
        <div className="step2_div">
          <div className="question_1">
            <h4>Do you train athletes for running ?</h4>
            <div className="yes_no_div">
              <label>
                <input
                  type="radio"
                  name="radio"
                  onChange={() => setRun("yes")}
                />
                <span>Yes</span>
              </label>

              <label>
                <input
                  type="radio"
                  name="radio"
                  onChange={() => setRun("no")}
                />
                <span>No</span>
              </label>
            </div>
          </div>

          {isRun === "yes" ? (
            <>
              <div className="question_2">
                <h4>Select distances</h4>
                <div className="yes_no_div">
                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="5k"
                      onChange={() => handleCheckboxChange("5k")}
                    />
                    <span>5k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="7k"
                      onChange={() => handleCheckboxChange("7k")}
                    />
                    <span>7k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="10k"
                      onChange={() => handleCheckboxChange("10k")}
                    />
                    <span>10k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="16k"
                      onChange={() => handleCheckboxChange("16k")}
                    />
                    <span>16k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="21k"
                      onChange={() => handleCheckboxChange("21k")}
                    />
                    <span>21k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="42k"
                      onChange={() => handleCheckboxChange("42k")}
                    />
                    <span>42k</span>
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="next_steps_btn">
            {popper && (
              <div className="popper">
                <p>fill up the fileds</p>
              </div>
            )}

            <p
              className="next_btn"
              onClick={() => {
                if (validation === true) {
                  setNextClicked(true);
                  setPopper(false);
                } else {
                  if (validation === false) {
                    setPopper(true);
                  }
                }
              }}
            >
              2/5Next <img src={RightArrow} alt="" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormStep2;
