import { useEffect, useRef, useState } from "react";
import ResetPassword from "./ResetPassword";
import { verifyOtp } from "../navController";
import "../css/popup.css";
import "../css/nav.css";

const OtpPopUp = ({
  length = 6,
  onOtpSubmit = () => {},
  setOpen,
  isOpen,
  setForgotPassword,
  email,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  const [otpVerify, setOtpVerify] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const otpNo = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };
  function handleCancel() {
    setForgotPassword(false);
    setOpen(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const res = await verifyOtp(email, otpNo);
    if (res === true) {
      setOtpVerify(true);
      setInvalidOtp(false);
    } else {
      setOtpVerify(false); // true
      setInvalidOtp(true);
    }
  }

  return (
    <>
      {!otpVerify ? (
        <div>
          {" "}
          <form onSubmit={handleSubmit}>
            <div className="otp_box">
              <span onClick={handleCancel} className="forgotPassword_cancel">
                Cancel
              </span>
              <h3 className="forgotPassword_heading">Enter OTP</h3>
              <p className="forgotPassword_message">
                A One-time-password has been sent to your registered email
                address. Check your Inbox, including Spam/Junk.
              </p>
              {otp.map((value, index) => {
                return (
                  <input
                    key={index}
                    type="text"
                    ref={(input) => (inputRefs.current[index] = input)}
                    value={value}
                    onChange={(e) => handleChange(index, e)}
                    onClick={() => handleClick(index)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className="otpInput"
                  />
                );
              })}
              <p className="forgotPassword_message resend_otp_text">
                Didn’t receive OTP? Resend
              </p>
              {invalidOtp && <span className="invalid_otp">Invalid OTP</span>}

              <div className="forgotPassword_submit">
                <button>Done</button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <ResetPassword
            setOpen={setOpen}
            isOpen={isOpen}
            setForgotPassword={setForgotPassword}
            email={email}
          />
        </div>
      )}
    </>
  );
};
export default OtpPopUp;
