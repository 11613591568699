import React from "react";
import Logo from "../../../common/Logo";

function PhoneNavBar(props) {
  const { isOpenP, setOpenP, isSignedIn, userInitial } = props;

  function handleClick() {
    setOpenP(!isOpenP);
  }

  return (
    <>
      <div className="phone_nav">
        <div className="phone_logo">
          <Logo />
        </div>
        <div className="menu_button">
          {!isSignedIn && (
            <svg
              onClick={handleClick}
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          )}
          {isSignedIn && (
            <div className="phone_Profile_logo" onClick={handleClick}>
              <span>{userInitial}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PhoneNavBar;
