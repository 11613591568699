import React, { useState, useEffect } from "react";
import { Button } from "rsuite";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SubmitData } from "../navController";
import playStore from "../../../assets/img/play-store.png";
import appStore from "../../../assets/img/apple-store.png";
import "../css/nav.css";
import "../css/mediaQuery.css";
import "rsuite/dist/styles/rsuite-default.min.css";
import { logout } from "../../../services/Header/Header";

function PopUpLoginP(props) {
  const {
    isOpenP,
    setOpenP,
    setIsSignedIn,
    isSignedIn,
    userInitial,
    RememberMe,
    setRememberMe,
    isForgotPassword,
    setForgotPassword,
    setSignUp,
    isSignUp,
    isSignInOpen,
    setSignInOpen,
    isSwitchBtn,
    SwitchAccountBtn,
  } = props;
  const role = localStorage.getItem("role") ?? sessionStorage.getItem("role");
  const [showLi, setShowLi] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isViewAccount, setViewAccount] = useState(false);
  const [load, setLoad] = useState(false);

  // Hide Input
  const [inputE, setInputE] = useState(false);
  const [inputP, setInputP] = useState(false);

  function handleClick() {
    setOpenP(false);
    setTimeout(() => {
      setSignInOpen(!isSignInOpen);
    }, 500);
  }

  function hanleClose() {
    setOpenP(false);
    setSignInOpen(false);
    setViewAccount(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);
    const req = await SubmitData(email, password, RememberMe, e);
    if (req === true) {
      setLoad(false);
      setSignInOpen(false);
      setIsSignedIn(true);
    } else if (req === false) {
      setLoad(false);
    }
  }

  function handleClickScrollCoach() {
    const element = document.getElementById("coach");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setOpenP(false);
  }

  function handleClickScrollAbout() {
    const element = document.getElementById("about");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setOpenP(false);
  }

  function handleClickScrollSupport() {
    console.log("click");
    const element = document.getElementById("footerP");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setOpenP(false);
  }

  function handleClickScrollAthele() {
    const element = document.getElementById("athlete");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setOpenP(false);
  }

  const location = useLocation();
  useEffect(() => {
    // Check if the user is on the specified URL
    if (
      location.pathname === "/MyBilling" ||
      location.pathname === "/ViewAccount" ||
      location.pathname === "/CoachForm" ||
      location.pathname === "/MyCoachingPlans"
    ) {
      setShowLi(false);
      setOpenP(false);
    } else {
      setShowLi(true);
    }
  }, [location]);

  return (
    <>
      <div className={`phone_menu_bar ${isOpenP ? "visible_menu" : ""}`}>
        <div className="close_button">
          {isSignedIn && !isViewAccount ? (
            <div className="phone_Profile_logo">
              <span>{userInitial}</span>
            </div>
          ) : (
            <div></div>
          )}
          <svg
            onClick={hanleClose}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className="bi bi-x-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
        {/* --------------------- UL -------------------- */}

        {isViewAccount ? (
          <div className="view_account_box">
            <div className="user_initial">
              <span>{userInitial}</span>
            </div>
            <div className="user_account">
              <div className="user_account_name_box">
                <h3>Mehul Jorwekar</h3>
                <p>mehul.jorwekar@gamil.com</p>
                <p>959398492</p>
              </div>
              <div className="user_account_dob_box">
                <p>Male</p>
                <p>01/11/2000</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="phone_nav_li_box">
            <div>
              <ul>
                {showLi && (
                  <>
                    <li onClick={handleClickScrollCoach}>Become a Coach</li>
                    <li onClick={handleClickScrollAthele}>For an Athlete</li>
                    <li onClick={handleClickScrollAbout}>About us</li>
                    <li onClick={handleClickScrollSupport}>Support</li>
                    {!isSignedIn && (
                      <li className="signin" onClick={handleClick}>
                        Sign In
                      </li>
                    )}
                  </>
                )}

                {isSignedIn && (
                  <>
                    <li>
                      <Link style={{ color: "white" }} to="/ViewAccount">
                        View Account
                      </Link>
                    </li>
                    <li>
                      <Link style={{ color: "white" }} to="/MyBilling">
                        My Billing
                      </Link>
                    </li>
                    {role === "Coach" && (
                      <li>
                        <Link style={{ color: "white" }} to="/MyCoachingPlans">
                          My Coaching Plans
                        </Link>
                      </li>
                    )}

                    {isSwitchBtn && (
                      <li onClick={() => SwitchAccountBtn()}>Switch Account</li>
                    )}
                    <li onClick={logout}>Logout</li>
                  </>
                )}
              </ul>
            </div>

            {/*----------- FOOTER NAV -----------*/}

            <div className="download_button">
              {!isViewAccount && (
                <div className="menu_footer">
                  <li>
                    <a href="mailto:support@trackofit.com">Help</a>
                  </li>
                  <li>
                    <a href="https://www.trackofit.com/privacypolicy.html">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a href="mailto:support@trackofit.com">Contact us</a>
                  </li>
                  <li onClick={handleClickScrollAbout}>About</li>
                </div>
              )}

              {/*----------- DOWNLOAD ICONS -----------*/}
              {isViewAccount ? (
                <div></div>
              ) : (
                <div className="donwload_img_phone">
                  <h4>Download App</h4>
                  <div className="download_img">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1"
                      className="play_store_a"
                    >
                      <img
                        className="play_store_img"
                        src={playStore}
                        alt="playStore"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/trackofit/id6469041232"
                      className="app_store_a"
                    >
                      <img
                        className="app_store_img"
                        src={appStore}
                        alt="appStore"
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {isSignInOpen && (
          <>
            <form
              onSubmit={handleSubmit}
              className={`login  ${isSignInOpen ? "visible_login" : ""}`}
            >
              <div className="login_header">
                <h2>SIGN IN</h2>
                <svg
                  onClick={hanleClose}
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </div>

              {/* ------------ FORM DIV ----------- */}
              <div>
                <input
                  onMouseEnter={() => setInputE(!inputE)}
                  onMouseOut={() => setInputE(false)}
                  type="email"
                  name="email"
                  placeholder={`  ${inputE ? "" : "Email"}`}
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  onMouseEnter={() => setInputP(!inputP)}
                  onMouseOut={() => setInputP(false)}
                  type="password"
                  placeholder={`  ${inputP ? "" : "Password"}`}
                  required
                  minLength="8"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="checkBox">
                <div>
                  <input
                    type="checkbox"
                    onChange={() => setRememberMe(!RememberMe)}
                  />
                  <span>Remember me</span>
                </div>
                <div>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setForgotPassword(!isForgotPassword);
                      setSignInOpen(false);
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="signup_btn">
                <p
                  onClick={() => {
                    setSignUp(!isSignUp);
                    setOpenP(false);
                  }}
                >
                  Don’t have a account? <span>Sign up</span>
                </p>
              </div>
              <div className="submit_box">
                <button className="submit" type="submit">
                  {load ? (
                    <Button
                      loading
                      className="submit_box"
                      style={{ backgroundColor: "#fdc027" }}
                    ></Button>
                  ) : (
                    <span>SIGN UP</span>
                  )}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default PopUpLoginP;
