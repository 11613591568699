const REST_API_ENDPOINT = process.env.REACT_APP_URL;

export const AppConstants = {
  JWT_TOKEN: "token",
  REST_API_LOGIN_ENDPOINT: REST_API_ENDPOINT + "/basic/user/login",
  REST_API_VERIFY_EMAIL: REST_API_ENDPOINT + "/basic/user/verify",
  REST_API_VERIFY_OTP: REST_API_ENDPOINT + "/basic/user/verify/otp",
  REST_API_SEND_OTP: REST_API_ENDPOINT + "/basic/user/send/otp",
  REST_API_UPDATE_PASSWORD: REST_API_ENDPOINT + "/basic/user/update/password",
  HEADER_PREFIX: "Bearer ",
  PATH_TO_BILLING: "billing",
  PATH_TO_HOME: "/",
  // REST_API_USER_DETAILS: REST_API_ENDPOINT + "/basic/user/get/details/ref",
  CASHFREE_API: REST_API_ENDPOINT + "/billing/order/create",
  COACHFORM_FORM_SUBMIT_API: REST_API_ENDPOINT + "/coach/signup",
  COACHFORM_PHOTO_UPLOAD_API: REST_API_ENDPOINT + "/files/upload",
  GET_USER_DETAILS: REST_API_ENDPOINT + "/basic/user/get/details",
  USER_SIGN_UP: REST_API_ENDPOINT + "/basic/user/signup",
  VIEW_ACCOUNT_EDIT: REST_API_ENDPOINT + "/basic/user/update/details",
  GET_COVER_IMG: REST_API_ENDPOINT + "/files/get/1",
  GET_PROFILE_IMG: REST_API_ENDPOINT + "/files/get/2",
  GET_COACH_SIGNUP_STATUS: REST_API_ENDPOINT + "/coach/signup/status/get",
  SWITCH_ACCOUNT: REST_API_ENDPOINT + "/basic/user/switch/",
  GET_COACH_FORM_DATA: REST_API_ENDPOINT + "/coach/signup/details/get",
  GET_COACHING_PLANS: REST_API_ENDPOINT + "/coaching/plans/list",
  ADD_COACHING_PLANS: REST_API_ENDPOINT + "/coaching/plans/create",
  UPDATE_COACHING_PLANS: REST_API_ENDPOINT + "/coaching/plans/update",
  DELETE_COACHING_PLANS: REST_API_ENDPOINT + "/coaching/plans/delete/",
  // REST_API_CURRENT_INVOICE: REST_API_ENDPOINT + "/billing/current",
  // REST_API_BILLING_HISTORY: REST_API_ENDPOINT + "/billing/history/2023",
  // PATH_TO_PAYMENT_API: "https://test.payu.in/_payment",
  // REST_API_PAYUMONEY_HASH: REST_API_ENDPOINT + "/payumoney/hash?invoiceId=",
  // REST_API_PAYUMONEY_SUCCESS: "/payumoney/success",
  // REST_API_PAYUMONEY_FAILURE: "/payumoney/failure",
};
