import React, { useEffect, useState } from "react";
import FormStep5 from "./FormStep5";
import RightArrow from "../../../../assets/icons/rightArrow.svg";
function FormStep4(props) {
  const {
    name,
    tagline,
    email,
    number,
    location,
    gender,
    aboutYourSelf,
    selectedDistancesRun,
    selectedDistancesSwim,
    isRun,
    coverPhotoPath,
    profilePhotoPath,
    certificates,
    isSwim,
  } = props;
  const [isCycle, setCycle] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [selectedDistancesCycle, setSelectedDistancesCycle] = useState([]);
  const [popper, setPopper] = useState(false);
  useEffect(() => {
    if (isCycle === "yes" || isCycle === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    if (selectedDistancesCycle.includes(value)) {
      setSelectedDistancesCycle(
        selectedDistancesCycle.filter((item) => item !== value)
      );
    } else {
      // Otherwise, add it to the array
      setSelectedDistancesCycle([...selectedDistancesCycle, value]);
    }
  };

  return (
    <div>
      {nextClicked && validation ? (
        <FormStep5
          name={name}
          tagline={tagline}
          email={email}
          number={number}
          location={location}
          gender={gender}
          aboutYourSelf={aboutYourSelf}
          selectedDistancesRun={selectedDistancesRun}
          selectedDistancesSwim={selectedDistancesSwim}
          selectedDistancesCycle={selectedDistancesCycle}
          isRun={isRun}
          coverPhotoPath={coverPhotoPath}
          profilePhotoPath={profilePhotoPath}
          certificates={certificates}
          isSwim={isSwim}
          isCycle={isCycle}
        />
      ) : (
        <div className="step2_div">
          <div className="question_1 ">
            <h4>Do you train athletes for Cycling ?</h4>
            <div className="yes_no_div cycle">
              <label htmlFor="yes">
                <input
                  type="radio"
                  name="radio"
                  id="yes"
                  onChange={() => setCycle("yes")}
                />
                <span>Yes</span>
              </label>

              <label htmlFor="no">
                <input
                  type="radio"
                  name="radio"
                  id="no"
                  onChange={() => setCycle("no")}
                />
                <span>No</span>
              </label>
            </div>
          </div>

          {isCycle === "yes" ? (
            <>
              <div className="question_2">
                <h4>Select distances</h4>
                <div className="yes_no_div cycle">
                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      value="40k"
                      onChange={() => handleCheckboxChange("40k")}
                    />
                    <span>40k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="90k"
                      onChange={() => handleCheckboxChange("90k")}
                    />
                    <span>90k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="100k"
                      onChange={() => handleCheckboxChange("100k")}
                    />
                    <span>100k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="160k"
                      onChange={() => handleCheckboxChange("160k")}
                    />
                    <span>160k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="200k"
                      onChange={() => handleCheckboxChange("200k")}
                    />
                    <span>200k</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="radio"
                      id=""
                      value="Time Trail"
                      onChange={() => handleCheckboxChange("Time Trail")}
                    />
                    <span>Time Trail</span>
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="next_steps_btn">
            {popper && (
              <div className="popper">
                <p>fill up the fileds</p>
              </div>
            )}

            <p
              className="next_btn"
              onClick={() => {
                if (validation === true) {
                  setNextClicked(true);
                  setPopper(false);
                } else {
                  if (validation === false) {
                    setPopper(true);
                  }
                }
              }}
            >
              4/5Next <img src={RightArrow} alt="" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormStep4;
