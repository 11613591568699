import React, { useState } from "react";
import { Buffer } from "buffer";
import show from "../../../assets/icons/OpenEye.svg";
import nonShow from "../../../assets/icons/CloseEye.svg";
import { updatePassword } from "../navController";

function ResetPassword(props) {
  const [showPassword, setShowPassword] = useState(false);
  const { setOpen, isOpen, setForgotPassword, email } = props;
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [isMatch, setMatch] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  function handleCancel() {
    setForgotPassword(false);
    setOpen(false);
  }

  async function handleSbumit(e) {
    e.preventDefault();

    if (password === repassword) {
      setMatch(false);
      const buffer = Buffer.from(password);
      const encrPassword = buffer.toString("base64");
      const res = await updatePassword(email, encrPassword);
      setForgotPassword(false);
      setOpen(false);
      setOpen(true);
    } else {
      setMatch(true);
    }
  }

  // password validation
  function validatePassword(password) {
    const passwordRegex = /^(?=.*[!@#$])[a-zA-Z0-9!@#$]{6,12}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Enter a 6 to 12 character password (a-z, A-Z, 0-9, !@#$) with at least one special character, or password do not match"
      );
    } else {
      setPasswordError("");
    }
  }

  return (
    <form onSubmit={handleSbumit}>
      <div className="resetpassword_box">
        <span onClick={handleCancel} className="forgotPassword_cancel">
          Cancel
        </span>
        <h3 className="forgotPassword_heading"> Reset Password</h3>
        <p className="forgotPassword_message">
          Enter a password 8-14 characters. It must contain letter, number and
          atleast 1 special character (. , - , _ , # , @)
        </p>
        <input
          className="input_box"
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Enter password"
          required
          onChange={(e) => {
            setPassword(e.target.value);
            validatePassword(e.target.value);
          }}
        />
        <div style={{ position: "relative" }}>
          <input
            className="input_box input_box_re"
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Re-Enter password"
            required
            onChange={(e) => {
              setRePassword(e.target.value);
            }}
          />
          <label
            className="show"
            htmlFor=""
            onClick={() => setShowPassword(!showPassword)}
          >
            <img src={showPassword ? nonShow : show} alt="" />
          </label>
          {isMatch && (
            <div style={{ textAlign: "right", width: "90%", margin: "0 auto" }}>
              <label className="match">Passwords do not Match</label>
            </div>
          )}
        </div>

        {passwordError && (
          <div
            style={{
              color: "#e00000",
              width: "90%",
              margin: "0.2rem auto",
              fontSize: ".6rem",
              textAlign: "left",
            }}
          >
            {passwordError}
          </div>
        )}
        <div className="forgotPassword_submit">
          <button>Reset</button>
        </div>
      </div>
    </form>
  );
}

export default ResetPassword;
