import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./components/Home";
import { SwitchAccount } from "./components/Coachform/viewCoachForm/viewCoachFormController";
import { SetDataInlocalStorage } from "./components/Coachform/viewCoachForm/viewCoachFormController";
import { getUserDetails } from "./components/ViewAccount/ViewAccountController";
import { logout } from "./services/Header/Header";

import MyBilling from "./components/MyBilling/MyBilling";
import CoachForm from "./components/Coachform/CoachForm";
import ViewAccount from "./components/ViewAccount/ViewAccount";
import ViewCoachForm from "./components/Coachform/viewCoachForm/ViewCoachForm";
import MyCoachingPlans from "./components/coachingPlans/MyCoachingPlans";

function App() {
  const coachform = true;
  // useState for Laptop navbar open
  const [isOpen, setOpen] = useState(false);

  // useState for Phone navbar open
  const [isOpenP, setOpenP] = useState(false);

  // sign in popup for phone (signin slider)
  const [isSignInOpen, setSignInOpen] = useState(false);

  // Checking the token in local storage
  const [isSignedIn, setIsSignedIn] = useState(false);

  // user initial
  const [userInitial, setUserInitial] = useState("");

  // view account get data
  const [getData, setData] = useState([]);

  // Switch account display or not
  const [isSwitchBtn, setSwitchBtn] = useState(false);

  // coach signup status => Pending verfication, Verified ect..
  const [coachSignUpStatus, setCoachSignupStatus] = useState("");

  //REMEMBER ME
  const [RememberMe, setRememberMe] = useState(false);

  // check if user is signed in or not
  useEffect(() => {
    const checkSignedStatus = () => {
      const token =
        localStorage.getItem("token") ?? sessionStorage.getItem("token");

      if (token) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    };

    checkSignedStatus();
  }, []);

  // user initial logic
  const userName =
    localStorage.getItem("name") ?? sessionStorage.getItem("name");
  useEffect(() => {
    if (userName === null) {
      console.log("return");
      return;
    } else {
      const ArryName = userName.split(" ");
      const filteredArray = ArryName.filter((str) => str !== "");

      if (filteredArray.length === 1) {
        setUserInitial(
          filteredArray[0].charAt(0).toUpperCase() +
            filteredArray[0].charAt(1).toLocaleUpperCase()
        );
      } else if (filteredArray.length === 2) {
        setUserInitial(
          (
            filteredArray[0].charAt(0) + filteredArray[1].charAt(0)
          ).toUpperCase()
        );
      } else {
        setUserInitial(
          (
            filteredArray[0].charAt(0) + filteredArray.reverse()[0].charAt(0)
          ).toUpperCase()
        );

        console.log(userInitial);
      }
    }
  }, [isOpen, isOpenP, isSignedIn, getData]);

  let ref_id;
  // logic for Switch account btn
  const fetchProfileData = async () => {
    try {
      const res = await getUserDetails();
      if (res.data.profiles.length > 0) {
        ref_id = res.data.profiles[0].ref;
        setSwitchBtn(true);
      } else {
        setSwitchBtn(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  fetchProfileData();

  // API call for Switch account
  async function SwitchAccountBtn() {
    try {
      console.log("hint");
      const res = await SwitchAccount(ref_id);
      if (res) {
        console.log(res);
        logout();
        SetDataInlocalStorage(res, RememberMe);
        const r =
          localStorage.getItem("role") ?? sessionStorage.getItem("role");
        setCoachSignupStatus("View Profile");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Routes>
        <Route
          element={
            <Home
              isOpen={isOpen}
              setOpen={setOpen}
              isOpenP={isOpenP}
              setOpenP={setOpenP}
              isSignedIn={isSignedIn}
              setIsSignedIn={setIsSignedIn}
              userInitial={userInitial}
              setUserInitial={setUserInitial}
              isSwitchBtn={isSwitchBtn}
              SwitchAccountBtn={SwitchAccountBtn}
              coachSignUpStatus={coachSignUpStatus}
              setCoachSignupStatus={setCoachSignupStatus}
              RememberMe={RememberMe}
              setRememberMe={setRememberMe}
              isSignInOpen={isSignInOpen}
              setSignInOpen={setSignInOpen}
            />
          }
          path="/"
        ></Route>
        <Route
          element={
            <MyBilling
              isOpen={isOpen}
              setOpen={setOpen}
              isOpenP={isOpenP}
              setOpenP={setOpenP}
              isSignedIn={isSignedIn}
              userInitial={userInitial}
              setIsSignedIn={setIsSignedIn}
              isSwitchBtn={isSwitchBtn}
              SwitchAccountBtn={SwitchAccountBtn}
            />
          }
          path="/MyBilling"
        ></Route>
        <Route
          element={
            <ViewAccount
              isOpen={isOpen}
              setOpen={setOpen}
              isOpenP={isOpenP}
              setOpenP={setOpenP}
              isSignedIn={isSignedIn}
              userInitial={userInitial}
              setIsSignedIn={setIsSignedIn}
              getData={getData}
              setData={setData}
              isSwitchBtn={isSwitchBtn}
              SwitchAccountBtn={SwitchAccountBtn}
            />
          }
          path="/ViewAccount"
        ></Route>

        <Route
          element={
            <CoachForm
              isOpen={isOpen}
              setOpen={setOpen}
              isOpenP={isOpenP}
              setOpenP={setOpenP}
              isSignedIn={isSignedIn}
              userInitial={userInitial}
              setIsSignedIn={setIsSignedIn}
              isSwitchBtn={isSwitchBtn}
              SwitchAccountBtn={SwitchAccountBtn}
            />
          }
          path="/CoachForm"
        ></Route>
        <Route
          element={
            <ViewCoachForm
              isOpen={isOpen}
              setOpen={setOpen}
              isOpenP={isOpenP}
              setOpenP={setOpenP}
              isSignedIn={isSignedIn}
              userInitial={userInitial}
              setIsSignedIn={setIsSignedIn}
              isSwitchBtn={isSwitchBtn}
              SwitchAccountBtn={SwitchAccountBtn}
            />
          }
          path="/ViewCoachform"
        ></Route>
        <Route
          element={
            <MyCoachingPlans
              isOpen={isOpen}
              setOpen={setOpen}
              isOpenP={isOpenP}
              setOpenP={setOpenP}
              isSignedIn={isSignedIn}
              userInitial={userInitial}
              setIsSignedIn={setIsSignedIn}
              isSwitchBtn={isSwitchBtn}
              SwitchAccountBtn={SwitchAccountBtn}
              isSignInOpen={isSignInOpen}
              setSignInOpen={setSignInOpen}
            />
          }
          path="/MyCoachingPlans"
        ></Route>
      </Routes>
    </>
  );
}

export default App;
