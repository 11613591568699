import React from "react";
import "./css/inputbox.css";

function InputBox(props) {
  const { name, setName, tagline, setTagline } = props;
  return (
    <div className="name_inputs">
      <div className="name">
        <label htmlFor="input" className="text">
          Name<sup style={{ color: "red" }}> *</sup>
        </label>
        <input
          type="text"
          placeholder=""
          name="input"
          className="input"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <div className="tagline">
        <label htmlFor="input" className="text">
          Tagline<sup style={{ color: "red" }}> *</sup>
        </label>
        <input
          type="text"
          placeholder=""
          name="input"
          className="input"
          value={tagline}
          onChange={(e) => setTagline(e.target.value)}
          required
        />
      </div>
    </div>
  );
}

export default InputBox;
