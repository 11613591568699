import React, { useEffect, useState } from "react";
import { EditViewAccount } from "../ViewAccountController";
import { getUserDetails } from "../ViewAccountController";
import "./css/profile_box.css";
function ProfileBox(props) {
  const { getData, setData } = props;

  // format date variable
  let new_date;

  // format date dd-mm-yyy
  useEffect(() => {
    if (getData.dob === undefined || null) {
      return;
    } else {
      const d = getData.dob.split("-")[0];
      const m = getData.dob.split("-")[1];
      const y = getData.dob.split("-")[2];
      new_date = `${d}-${m}-${y}`;
    }
  });

  //all  use states
  const [isType, setType] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [name, setName] = useState(getData.name);
  const [tagline, setTagline] = useState(getData.tagline);
  const [number, setNumber] = useState(getData.mobile);
  const [athleteType, setAthleteType] = useState(getData.athlete_type);
  const [dob, setDob] = useState(new_date);
  const [gender, setGender] = useState(getData.gender);
  const [location, setLocation] = useState(getData.location);

  // covert M,F,T into male, female, Trans
  useEffect(() => {
    if (getData.gender === "M") {
      setGender("Male");
    } else if (getData.gender === "F") {
      setGender("Female");
    } else {
      setGender("Trans");
    }
  }, [getData.gender]);

  // Function  when save is clicked
  async function handleSave() {
    if (name === "" || number === "" || gender === "") {
      alert("Fill up the fields");
    } else {
      const res = await EditViewAccount(name, number, athleteType, dob, gender);
      if (res === true) {
        const fetchData = async () => {
          try {
            const res = await getUserDetails();
            setData(res.data);
            console.log(getData.dob);
          } catch (error) {
            console.log(error);
          } finally {
            console.log("loading");
          }
        };
        fetchData();
        setEdit(false);
        setType(false);
      }
    }
  }

  // date validation => when date is send to backend we are changing the format to d-mm-yyyy
  function handleDate(e) {
    let date = e.target.value;
    const d = date.split("-")[2];
    const m = date.split("-")[1];
    const y = date.split("-")[0];
    let newDate = `${d}-${m}-${y}`;
    setDob(newDate);
  }

  // validation for date input field(can select current date - 18)
  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 18));
    return maxDate.toISOString().split("T")[0];
  };

  // NUMBER VALIDATION
  const NumberValidation = (e) => {
    const input = e.target.value;
    // Ensure input contains only digits and limit to 10 characters
    if (/^\d{0,10}$/.test(input)) {
      setNumber(input);
    }
  };

  return (
    <div className="profile_box">
      <div className="heading_edit_box">
        <h2 className="heading">Profile</h2>
        {isEdit ? (
          <div style={{ display: "flex" }}>
            <p className="edit_button" onClick={() => setEdit(false)}>
              Cancel
            </p>
            <p className="edit_button" onClick={handleSave}>
              Save
            </p>
          </div>
        ) : (
          <p className="edit_button" onClick={() => setEdit(true)}>
            Edit
          </p>
        )}
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Name</p>
          {isEdit ? (
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          ) : (
            <p className="profile_title">{getData.name}</p>
          )}
        </div>
        <div className="second_half">
          <p className="profile_lable">Tagline</p>
          {isEdit ? (
            <input
              type="text"
              value={tagline}
              onChange={(e) => setTagline(e.target.value)}
            />
          ) : (
            <p className="profile_title">
              {getData.tagline === undefined ? (
                <span>&ndash;</span>
              ) : (
                `${getData.tagline}`
              )}{" "}
            </p>
          )}
        </div>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Email</p>
          <p className="profile_title">{getData.email}</p>
        </div>
        <div className="second_half">
          <p className="profile_lable">Phone</p>
          {isEdit ? (
            <input
              type="number"
              pattern="\d{10}"
              maxLength={10}
              value={number}
              onChange={NumberValidation}
            />
          ) : (
            <p className="profile_title">
              {getData.mobile === undefined ? (
                <span>&ndash;</span>
              ) : (
                `${getData.mobile}`
              )}
            </p>
          )}
        </div>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Location</p>
          {isEdit ? (
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          ) : (
            <p className="profile_title">
              {getData.location === undefined ? (
                <span>&ndash;</span>
              ) : (
                `${getData.location}`
              )}
            </p>
          )}
        </div>
        <div className="second_half">
          <p className="profile_lable">Gender</p>

          {isEdit ? (
            <div className=" mydict_viewAccount">
              <div className="gender_inputs">
                <label>
                  <input
                    type="radio"
                    name="radio"
                    value={gender}
                    onChange={() => setGender("Male")}
                  />
                  <span>Male</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="radio"
                    value={gender}
                    onChange={() => setGender("Female")}
                  />
                  <span>Female</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="radio"
                    value={gender}
                    onChange={() => setGender("Trans")}
                  />
                  <span>Trans</span>
                </label>
              </div>
            </div>
          ) : (
            <p className="profile_title">
              {getData.gender === undefined ? (
                <span>&ndash;</span>
              ) : (
                `${gender}`
              )}
            </p>
          )}
        </div>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Birth date</p>
          {isEdit ? (
            <input
              type={`${isType ? "date" : "text"}`}
              onChange={handleDate}
              max={getMaxDate()}
              onFocus={() => setType(true)}
              placeholder={getData.dob}
            />
          ) : (
            <p className="profile_title">
              {getData.dob === undefined ? (
                <span>&ndash;</span>
              ) : (
                `${getData.dob}`
              )}
            </p>
          )}
        </div>
        <div className="second_half">
          <p className="profile_lable">Password</p>
          <p className="profile_title">***********</p>
        </div>
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Athlete Type</p>

          {isEdit ? (
            <select
              name="Athlete Type"
              id=""
              onChange={(e) => {
                setAthleteType(e.target.value);
              }}
            >
              <option value="" disabled defaultValue hidden></option>
              <option value="Runner">Runner</option>
              <option value="Duathelete">Duathelete</option>
              <option value="Triathlete">Triathlete</option>
              <option value="Swimmer">Swimmer</option>
              <option value="Biker">Biker</option>
            </select>
          ) : (
            <p className="profile_title">
              {getData.athlete_type === undefined ? (
                <span>&ndash;</span>
              ) : (
                `${getData.athlete_type}`
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileBox;
