import axios from "axios";
import { AppConstants } from "../../app.constant";
import { Header } from "../../services/Header/Header.js";

export async function getSessionId() {
  const header = Header();
  return axios
    .post(
      AppConstants.CASHFREE_API,
      {
        id: "qtnxn6rKnDhEyoKaxzo/J8xhjMKCHsYg+XcR6GYpGrZf",
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        return res.data.message;
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
}

export const PaynowBox = {
  id: "TS2425-0011",
  billing_period_from: "1-04-2024",
  billing_period_to: "08-05-2024",
  payment_status_code: 1,
  discount: 0.0,
  taxes: 957.63,
  total: 3000.0,
  status: "Unpaid",
  "paid on": "07-04-2024 10:10:10",
};

// logic for OVER DUE REMAINING DAYS
export function compareDate() {
  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(year, month - 1, day);
  };
  const currentDate = new Date();
  const dateFromData = PaynowBox.billing_period_from;
  const dateObject = convertToDate(dateFromData);
  if (dateObject < currentDate) {
    const differenceInMs = dateObject.getTime() - currentDate.getTime();
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    const overDueDate = -Math.ceil(differenceInDays);
    return overDueDate;
  } else {
    console.log("false");
    return false;
  }
}
