import React, { useState } from "react";
import { SubmitVideo } from "../FormController";
import "./css/formHeaderRight.css";

function FormHeaderRight(props) {
  const { setAoutYourSelf } = props;
  const [videoFileURL, setVidepFileURL] = useState("");
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0]; // Get the selected file
  //   const videoElement = document.getElementById("video");

  //   if (file && file.type.startsWith("video/")) {
  //     // If the selected file is a video
  //     const fileURL = URL.createObjectURL(file); // Create a URL for the selected file
  //     videoElement.src = fileURL; // Set the source of the video element to the file URL
  //   } else {
  //     // If the selected file is not a video
  //   }
  // };
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file

    if (file && file.type.startsWith("video/")) {
      // If the selected file is a video
      const fileURL = URL.createObjectURL(file); // Create a URL for the selected file
      const videoElement = document.createElement("video"); // Create a temporary video element

      // Set up an event listener to capture the video metadata
      const handleMetadataLoaded = () => {
        // Once the metadata is loaded, you can access the duration property
        const durationInSeconds = Math.round(videoElement.duration);
        console.log("Video duration:", durationInSeconds, "seconds");

        // Remove the event listener after it has been executed
        videoElement.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );

        // Check if the duration meets your requirements
        if (durationInSeconds <= 30) {
          // Set the source of the video element to the file URL
          // videoElement.src = fileURL;
          setVidepFileURL(fileURL);
        } else {
          // Notify the user that the video duration exceeds the limit
          alert("Video duration exceeds the maximum allowed duration.");
        }
      };

      // Add event listener for loadedmetadata event
      videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);

      // Load the file URL into the temporary video element to trigger the 'loadedmetadata' event
      videoElement.src = fileURL;
    } else {
      // If the selected file is not a video
      alert("Please select a valid video file.");
    }
  };

  return (
    <div className="form_header_right">
      <div className="about_div">
        <h4>
          Write something about yourself so that your athletes can understand
          you well.{" "}
        </h4>
        <div class="yourself">
          <label for="input" class="text">
            About Yourself:
          </label>
          {/* <input
            type="text"
            placeholder="Hello i am..."
            name="input"
            class="input"
          /> */}
          <textarea
            id="w3review"
            name="w3review"
            rows="10"
            class="input"
            onChange={(e) => setAoutYourSelf(e.target.value)}
          >
            Hello i am...
          </textarea>
        </div>
      </div>

      <div className="video_div">
        <h4>You can upload a small 1min video talking about yourself.</h4>
        <video id="video" controls src={videoFileURL}></video>
        <input
          id="file-input"
          type="file"
          accept="video/*"
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
}

export default FormHeaderRight;
