import React from "react";
import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoText = styled.a`
  letter-spacing: 1px;

  color: #fb8500;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.3rem;
  font-family: payToneOne !important;
`;

export default function Logo() {
  return (
    <LogoWrapper>
      <LogoText href="/">TRACKOFIT</LogoText>
    </LogoWrapper>
  );
}
