import React, { useEffect, useState } from "react";
import SendOtp from "./SendOtp";
import { Buffer } from "buffer";
import { Button } from "rsuite";
import { sendOtp, submitDataSignUp, verifyEmail } from "../../navController";
import "../../css/signup.css";
import show from "../../../../assets/icons/OpenEye.svg";
import nonShow from "../../../../assets/icons/CloseEye.svg";
import "rsuite/dist/styles/rsuite-default.min.css";

function SignUpPopUp(props) {
  const today = new Date();
  const [load, setLoad] = useState(false);
  const { setSignUp, isSignUp, setOpen } = props;
  const [otpVerify, setOtpVerify] = useState(false);
  const [isSendOtp, setSendOtp] = useState(false);
  const [done, setDone] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dd, setDd] = useState("");
  const [mm, setMm] = useState("");
  const [yyyy, setYyyy] = useState("");
  const [emailExist, setEmailExist] = useState(false);
  const [activity, setActivity] = useState("");
  const [gender, setGender] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isBlur, setBlur] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const [isValidEmail, setValidEmail] = useState(false);
  const [below18, setBelow18] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [TAC, setTAC] = useState(false);
  const [ads, setAds] = useState(true);
  const signupDisable =
    !name || !email || !password || !TAC || !gender || !activity;
  const [count, setCount] = useState(1);

  // API call for send otp
  async function handleSendOtp(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);
    const req = await verifyEmail(email);
    if (req === true) {
      setLoad(false);
      setEmailExist(true);
    } else {
      setLoad(false);
      const req = await sendOtp(email);
      if (req === true) {
        if (isValidEmail === true) {
          if (count > 3) {
            alert("Try after 10min");
            setTimeout(() => {
              setCount(0);
            }, 60000);
          } else {
            setCount(count + 1);
            setSendOtp(true);
            setBlur(true);
            setEmailExist(false);
          }
        } else {
          alert("invalid email");
        }
      } else {
        alert("Network error try again later");
      }
    }
  }

  // remove otp popup
  useEffect(() => {
    if (done === true) {
      setSendOtp(false);
    }
  }, [done]);

  // -------------- EMAIL VALIDATION ----------------
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  }

  // -------------- password VALIDATION ----------------
  function validatePassword(password) {
    const passwordRegex = /^(?=.*[!@#$])[a-zA-Z0-9!@#$]{6,12}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Enter a 6 to 12 character password (a-z, A-Z, 0-9, !@#$) with at least one special character."
      );
    } else {
      setPasswordError("");
    }
  }

  // SIGUP POPUP CLOSE
  function handleClose() {
    setSignUp(false);
  }

  // -------------- Date validation --------------------
  function ageDff(sd, sm, sy) {
    const month = today.getMonth() + 1; // getMonth() returns month from 0-11, so add 1
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = date + "/" + month + "/" + year;
    const userDate = new Date(`${sy}-${sm}-${sd}`);

    // Calculate the difference in years
    const yearDifference = year - userDate.getFullYear();

    // Adjust year difference if the birthday hasn't occurred yet this year
    const hasHadBirthdayThisYear =
      month > userDate.getMonth() + 1 ||
      (month === userDate.getMonth() + 1 && date >= userDate.getDate());

    const adjustedYearDifference = hasHadBirthdayThisYear
      ? yearDifference
      : yearDifference - 1;

    console.log(adjustedYearDifference);

    if (adjustedYearDifference < 18 && adjustedYearDifference > 0) {
      setBelow18(true);
    } else {
      setBelow18(false);
    }
  }

  // dd validation
  function handleDd(e) {
    if (e.target.value > 31 || e.target.value < 0) {
      e.target.value = e.target.value.slice(0, -1);
    } else {
      setDd(e.target.value);
    }
    ageDff(e.target.value, mm, yyyy);
  }

  // month validation
  function handleMm(e) {
    if (e.target.value > 12 || e.target.value < 0) {
      e.target.value = e.target.value.slice(0, -1);
    } else {
      setMm(e.target.value);
    }
    ageDff(dd, e.target.value, yyyy);
  }

  // year validation
  function handleYyyy(e) {
    setYyyy(e.target.value);
    ageDff(dd, mm, e.target.value);
  }

  // ------------------------------- ON SUBMIT ----------------------------
  async function handleSubmit(e) {
    e.preventDefault();
    let dob = `${dd}-${mm}-${yyyy}`;
    const buffer = Buffer.from(password);
    const encrPassword = buffer.toString("base64");
    const res = await submitDataSignUp(
      name,
      email,
      encrPassword,
      activity,
      gender,
      TAC,
      dob
    );
    if (res === true) {
      console.log(res);
      setSignUp(false);
      setOpen(true);
    }
  }

  return (
    <>
      {isSendOtp && (
        <>
          <SendOtp
            setDone={setDone}
            email={email}
            setBlur={setBlur}
            setDisable={setDisable}
            setSendOtp={setSendOtp}
            setCount={setCount}
            count={count}
            setOtpVerify={setOtpVerify}
            otpVerify={otpVerify}
          />
        </>
      )}
      <form action="">
        {isBlur && <div className="blur"></div>}

        <div className="signup_box">
          <div className="signup_heading">
            <h3>Create your Account</h3>
          </div>
          <div className="cancel_btn_signup" onClick={handleClose}>
            <p>Cancel</p>
          </div>
          {/*-------------- Name Email inputs ------------*/}
          <div className="sigup_inputs">
            <div className="input_box_s">
              <input
                type="text"
                name="name"
                value={name}
                placeholder="First and last name"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="input_box_s">
              <div className="guide_text">
                <p>Your Email address will be your username</p>
              </div>
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Email Address"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
                disabled={done}
              />

              <div className="error_message_s">
                {emailExist && <p>Email exists</p>}
              </div>
              <div className="guide_text">
                {otpVerify ? (
                  <p>
                    <span className="click">Verified</span> Your email is
                    verified
                  </p>
                ) : (
                  <p>
                    <span className="click" onClick={handleSendOtp}>
                      {load ? (
                        <Button
                          loading
                          className="submit_box"
                          style={{ backgroundColor: "#04b801" }}
                        ></Button>
                      ) : (
                        <span>Click here</span>
                      )}
                    </span>
                    to verify your email address to proceed.
                  </p>
                )}
              </div>
            </div>
          </div>
          {/*-------------- Gender INput -------------------*/}
          <div className="sigup_inputs gender_input_activity">
            <div className="input_box_S">
              <select
                name="Primary Activity"
                id=""
                disabled={isDisable}
                onChange={(e) => {
                  setActivity(e.target.value);
                }}
              >
                <option value="" disabled selected hidden>
                  Primary Activity
                </option>
                <option value="Runner">Runner</option>
                <option value="Duathelete">Duathelete</option>
                <option value="Triathlete">Triathlete</option>
                <option value="Swimmer">Swimmer</option>
                <option value="Swimmer">Biker</option>
              </select>
            </div>
            <div className="input_box_S">
              <select
                name="Gender"
                id=""
                disabled={isDisable}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <option value="" disabled selected hidden>
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">female</option>
                <option value="Trans">Trans</option>
              </select>
            </div>
          </div>
          {/*--------- password input -------------*/}
          <div className="sigup_inputs password_box">
            <div className="input_box_s">
              <div className="guide_text">
                <p>Enter a 6 to 12 character password (a-z, A-Z, 0-9, !@#$)</p>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  disabled={isDisable}
                  type={showPassword ? "text" : "password"}
                  name="email"
                  placeholder="Enter password"
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                />
                <label
                  className="show_password"
                  htmlFor=""
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <img src={showPassword ? nonShow : show} alt="" />
                </label>
              </div>
              {passwordError && (
                <div
                  style={{
                    color: "#e00000",
                    width: "90%",
                    margin: "0.2rem auto",
                    fontSize: ".6rem",
                  }}
                >
                  {passwordError}
                </div>
              )}
            </div>
          </div>
          {/*--------------- Date INPUTS -----------*/}
          <div className="sigup_inputs">
            <div className="input_box_s ">
              <div className="guide_text date_guide_text">
                <p>
                  <span>Date of Birth </span>
                  <br />
                  You need to be 18 years and older to create an account
                </p>
              </div>
              <div className="date_input">
                <input
                  type="number"
                  value={dd}
                  name=""
                  placeholder="DD"
                  required
                  disabled={isDisable}
                  onChange={handleDd}
                />
                <input
                  type="number"
                  name=""
                  placeholder="MM"
                  required
                  disabled={isDisable}
                  min="1"
                  max="12"
                  onChange={handleMm}
                />
                <input
                  type="number"
                  name=""
                  value={yyyy}
                  placeholder="YYYY"
                  required
                  disabled={isDisable}
                  onChange={handleYyyy}
                />
              </div>
              <div style={{ width: "90%", margin: "0 auto" }}>
                {below18 && <p style={{ color: "red" }}>below 18</p>}
              </div>
            </div>
          </div>
          <div className="terms_condition_box">
            <input
              type="checkbox"
              id="terms"
              disabled={isDisable}
              checked={ads}
              value="ads"
              onChange={() => setAds(!ads)}
            />
            <label htmlFor="terms">Send mail regarding advertisment</label>
          </div>
          <div className="terms_condition_box">
            <input
              type="checkbox"
              id="terms"
              disabled={isDisable}
              value="TAC"
              onChange={() => setTAC(!TAC)}
            />
            <label htmlFor="terms">
              I agree to the Terms of use,{" "}
              <a href="https://www.trackofit.com/privacypolicy.html">
                Privacy Policy{" "}
              </a>
              and <a href="">Refund Policy</a>
            </label>
          </div>

          <div className="signup_submit">
            <button
              onClick={handleSubmit}
              style={{
                backgroundColor: isDisable ? "" : "#fdc027",
                color: isDisable ? "#626262" : "black",
              }}
              disabled={signupDisable}
            >
              SIGN UP
            </button>
          </div>
          <div className="have_acc">
            <p>
              Already have a account?{" "}
              <span
                style={{ color: "#2261ffda", cursor: "pointer" }}
                onClick={() => {
                  setSignUp(false);
                  setOpen(true);
                }}
              >
                Sign in
              </span>
            </p>
          </div>
        </div>
      </form>
      {isSignUp && (
        <div
          onClick={handleClose}
          className="desktop_popup_background signup_desktop_popup_background"
        ></div>
      )}
    </>
  );
}

export default SignUpPopUp;
