import React, { useEffect, useState } from "react";
import Nav from "../NavBarM/DesktopComponents/Nav";
import PhoneNavBar from "../NavBarM/PhoneComponents/PhoneNavBar";
import InputBox from "./formcomponents/InputBox";
import RightArrow from "../../assets/icons/rightArrow.svg";
import FormHeaderLeft from "./formcomponents/FormHeaderLeft";
import FormHeaderRight from "./formcomponents/FormHeaderRight";
import FormStep2 from "./formcomponents/formSteps/FormStep2";
import PhoneFormStep1 from "./formcomponents/PhoneSteps/PhoneFormStep1";
import PhoneFormStep2 from "./formcomponents/PhoneSteps/PhoneFormStep2";
import PopUpLoginP from "../NavBarM/PhoneComponents/PopUpLoginP.jsx";
import "./addcoach.css";
import styled from "styled-components";
import { SubmitCoverPhoto } from "./FormController";
import { SubmitProfilePhoto } from "./FormController";

const CoverPhoto = styled.div``;

function CoachForm(props) {
  const {
    isOpen,
    setOpen,
    isOpenP,
    setOpenP,
    isSignedIn,
    userInitial,
    setIsSignedIn,
    isSwitchBtn,
    SwitchAccountBtn,
  } = props;
  // Valition Logic
  const [certificates, setCertificates] = useState([]);
  const [isNavBar, setNavBar] = useState(false);
  const [formValidation, setFormValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [name, setName] = useState("");
  const [tagline, setTagline] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [location, setLocation] = useState("");
  const [gender, setGender] = useState("");
  const [aboutYourSelf, setAoutYourSelf] = useState("");
  const [popper, setPopper] = useState(false);
  const [coverPhotoPath, setCoverPhotoPath] = useState(null);
  const [profilePhotoPath, setProfilePhotoPath] = useState(null);
  // email validation
  const [isValidEmail, setIsValidEmail] = useState(true);
  // Validation logic -------------------------------
  useEffect(() => {
    if (
      email === "" ||
      number === "" ||
      location === "" ||
      gender === "" ||
      name === "" ||
      tagline === "" ||
      !isValidEmail
    ) {
      setFormValidation(false);
    } else {
      setFormValidation(true);
    }
  });

  //cover photo display logic -----------------------------------------------------------------------
  let coverPhotoFileURL = "";
  const [photoURL, setPhotoURL] = useState(null);
  async function checkStatusCoverPhoto(file) {
    const req = await SubmitCoverPhoto(file);

    if (req.success === true) {
      console.log("coverPhoto Path: " + req.path);
      setPhotoURL(coverPhotoFileURL);
      setCoverPhotoPath(req.path);
    } else {
      alert("Network error");
    }
  }

  // ----------------- handle cover photo change ----------------
  const handleFileChange = (event) => {
    let fi = document.getElementById("file-input");
    // Check if any file is selected.
    if (fi.files.length > 0) {
      for (let i = 0; i <= fi.files.length - 1; i++) {
        let fsize = fi.files.item(i).size;
        let file = Math.round(fsize / 1024);
        // The size of the file.
        console.log(file);
        if (file >= 418) {
          alert("File too Big, please select a file less than 410kb");
        } else {
          const file = event.target.files[0]; // Get the selected file

          if (file && file.type.startsWith("image/")) {
            // If the selected file is an image
            coverPhotoFileURL = URL.createObjectURL(file); // Create a URL for the selected file

            // Create an Image object to get the dimensions
            const img = new Image();
            img.onload = () => {
              let imageWidth = img.width;
              let imageHeight = img.height;
              console.log("Image dimensions:" + imageWidth + "x" + imageHeight);
              // Add your validation logic here based on imageWidth and imageHeight
              if (img.width === 1235 && img.height === 338) {
                checkStatusCoverPhoto(file);
                console.log(file);
              } else {
                alert("Image dimension should be less then 1235px X 338px");
              }
            };
            img.src = coverPhotoFileURL; // Set the source of the Image object to the file URL
          }
        }
      }
    }
  };

  //-------------------------------- Profile Photo logic -----------------------------
  const [profilePhotoURL, setProfilePhotoURL] = useState(null);
  let profilePhotoFileURL = "";
  async function checkStatusProfilePhoto(file) {
    const req = await SubmitProfilePhoto(file);
    console.log("profile photo path:- " + req.path);
    if (req.success === true) {
      setProfilePhotoURL(profilePhotoFileURL);
      setProfilePhotoPath(req.path);
    } else {
      alert("Network error");
    }
  }
  const handleProfilePhoto = (e) => {
    let fi = document.getElementById("profilePhoto");
    // Check if any file is selected.

    if (fi.files.length > 0) {
      for (let i = 0; i <= fi.files.length - 1; i++) {
        let fsize = fi.files.item(i).size;
        let file = Math.round(fsize / 1024);
        console.log(file);
        // The size of the file.
        if (file >= 103) {
          alert("File too Big, please select a file less than 100kb");
        } else {
          const file = e.target.files[0]; // Get the selected file
          if (file && file.type.startsWith("image/")) {
            // If the selected file is an image
            profilePhotoFileURL = URL.createObjectURL(file); // Create a URL for the selected file
            // Create an Image object to get the dimensions
            const img = new Image();
            img.onload = () => {
              let imageWidth = img.width;
              let imageHeight = img.height;
              console.log("Image dimensions:" + img.width + "x" + imageHeight);
              // Add your validation logic here based on imageWidth and imageHeight
              if (img.width === 320 && img.height === 320) {
                checkStatusProfilePhoto(file);
              } else {
                alert("Image dimension should be less then 320px X 320px");
              }
            };
            img.src = profilePhotoFileURL; // Set the source of the Image object to the file URL
          }
        }
      }
    }
  };
  return (
    <>
      <div className="coach_form">
        {/* <CoachFormNavBar isNavBar={isNavBar} /> */}
        <Nav
          isOpen={isOpen}
          setOpen={setOpen}
          userInitial={userInitial}
          isSignedIn={isSignedIn}
          isSwitchBtn={isSwitchBtn}
          SwitchAccountBtn={SwitchAccountBtn}
        />
        <PhoneNavBar
          isOpenP={isOpenP}
          setOpenP={setOpenP}
          isSignedIn={isSignedIn}
          userInitial={userInitial}
        />
        <PopUpLoginP
          isOpenP={isOpenP}
          setOpenP={setOpenP}
          setIsSignedIn={setIsSignedIn}
          isSignedIn={isSignedIn}
          userInitial={userInitial}
        />
        <div className="form_section">
          <form action="" method="post" style={{ marginTop: "4rem" }}>
            {formValidation && nextClicked ? (
              <>
                <FormStep2
                  name={name}
                  tagline={tagline}
                  email={email}
                  number={number}
                  location={location}
                  gender={gender}
                  aboutYourSelf={aboutYourSelf}
                  coverPhotoPath={coverPhotoPath}
                  profilePhotoPath={profilePhotoPath}
                  certificates={certificates}
                />
                <PhoneFormStep2
                  name={name}
                  tagline={tagline}
                  email={email}
                  number={number}
                  location={location}
                  gender={gender}
                  certificates={certificates}
                  setCertificates={setCertificates}
                />
              </>
            ) : (
              <>
                {/* Cover photo DIV */}
                <CoverPhoto className="cover_photo">
                  {photoURL && (
                    <img
                      className="cover_photo_img"
                      id="photo"
                      src={photoURL}
                      alt="Uploaded"
                    />
                  )}
                  <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ visibility: "hidden" }}
                  />
                  <label className="cover_photo_input" htmlFor="file-input">
                    Add Cover Photo
                  </label>

                  {/* PORFILE PHOTO DIV */}
                  <div className="profile_input_box">
                    <div className="profile_photo">
                      <img
                        className="profile_photo_img"
                        src={profilePhotoURL}
                        alt=""
                      />
                      <input
                        type="file"
                        accept="image/*"
                        name=""
                        placeholder="+"
                        id="profilePhoto"
                        onChange={handleProfilePhoto}
                        style={{ visibility: "hidden" }}
                      />
                      <label
                        className="profile_photo_input"
                        htmlFor="profilePhoto"
                      ></label>
                    </div>
                    {/* INPUTS box */}
                    <InputBox
                      name={name}
                      setName={setName}
                      tagline={tagline}
                      setTagline={setTagline}
                    />
                  </div>
                </CoverPhoto>
                <div className="from_header">
                  <FormHeaderLeft
                    email={email}
                    setEmail={setEmail}
                    number={number}
                    setNumber={setNumber}
                    location={location}
                    setLocation={setLocation}
                    setGender={setGender}
                    gender={gender}
                    formValidation={formValidation}
                    certificates={certificates}
                    setCertificates={setCertificates}
                  />
                  <FormHeaderRight setAoutYourSelf={setAoutYourSelf} />

                  <PhoneFormStep1
                    formValidation={formValidation}
                    nextClicked={nextClicked}
                    email={email}
                    setEmail={setEmail}
                    number={number}
                    setNumber={setNumber}
                    location={location}
                    setLocation={setLocation}
                    setGender={setGender}
                    gender={gender}
                    isValidEmail={isValidEmail}
                    setIsValidEmail={setIsValidEmail}
                  />
                </div>
                <div className="next_steps_btn">
                  {popper && (
                    <div className="popper">
                      <p>Fill up the fileds</p>
                    </div>
                  )}

                  <p
                    className="next_btn"
                    onClick={() => {
                      if (formValidation === true) {
                        setNextClicked(true);
                        setNavBar(true);
                        setPopper(false);
                      } else {
                        if (formValidation === false) {
                          setPopper(true);
                        }
                      }
                    }}
                  >
                    1/5Next <img src={RightArrow} alt="" />
                  </p>
                </div>
                <div className="Phone_next_steps_btn">
                  {popper && (
                    <div className="popper">
                      <p>Fill up the fileds</p>
                    </div>
                  )}
                  <p
                    className="next_btn"
                    onClick={() => {
                      if (formValidation === true) {
                        setNextClicked(true);
                        setNavBar(true);
                        setPopper(false);
                      } else {
                        if (formValidation === false) {
                          setPopper(true);
                        }
                      }
                    }}
                  >
                    1/7Next <img src={RightArrow} alt="" />
                  </p>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default CoachForm;
