import React, { useState } from "react";
import {
  AddCoachingPlans,
  UpdateCoachingPlan,
} from "../MyCoachingPlansController";
import "../css/addpopup.css";

function AddPopup(props) {
  const { setAdd, edit, setEdit, planId, name, description, planCharge } =
    props;
  const [planName, setPlanName] = useState("");
  const [planBrief, setPlanBrief] = useState("");
  const [planDetails, setPlanDetails] = useState("");

  const [planPrice, setPlanPrice] = useState("");

  const isTax = true;
  let planDescription;
  async function handleAdd() {
    planDescription = [planBrief, planDetails];
    const res = await AddCoachingPlans(
      planName,
      planDescription,
      planPrice,
      isTax
    );
    if (res === true) {
      setAdd(false);
      setEdit(false);
    } else {
      alert("Plan error. Your plan is not added");
    }
  }
  async function handleEdit() {
    planDescription = [planBrief, planDetails];
    const res = await UpdateCoachingPlan(
      planName,
      planDescription,
      planPrice,
      isTax,
      planId
    );
    if (res === true) {
      setAdd(false);
      setEdit(false);
    } else {
      alert("Plan error. Your plan is not edited");
    }
    setAdd(false);
  }

  return (
    <div className="popup_box">
      <div className="cancel_btn">
        <p
          onClick={() => {
            setAdd(false);
            setEdit(false);
          }}
        >
          Cancel
        </p>
      </div>
      <div className="popup_heading">
        {edit ? <h4>Edit Coaching Plan</h4> : <h4>Add Coaching Plan</h4>}
      </div>
      <div className="inputs_box">
        <div className="input_lable_div">
          <label htmlFor="">Plan name</label>
          <input type="text" onChange={(e) => setPlanName(e.target.value)} />
        </div>

        <div className="input_lable_div">
          <label htmlFor="">Plan Brief Details</label>
          <input type="text" onChange={(e) => setPlanBrief(e.target.value)} />
        </div>

        <div className="input_lable_div">
          <label htmlFor="">Plan Details</label>
          <textarea
            type="text"
            rows="5"
            onChange={(e) => setPlanDetails(e.target.value)}
          />
        </div>

        <div className="input_lable_div last_box">
          <label htmlFor="">
            Plan Price (Enter plan price including tax's)
          </label>
          <input type="text" onChange={(e) => setPlanPrice(e.target.value)} />
        </div>

        {/* <div className="Popup_check_box">
          <input type="checkbox" onChange={(e) => setTax(!isTax)} />
          <label htmlFor="">Plan fees including tax</label>
        </div> */}
        {edit ? (
          <div className="add_btn_poup" onClick={handleEdit}>
            Edit Plan
          </div>
        ) : (
          <div className="add_btn_poup" onClick={handleAdd}>
            Add Plan
          </div>
        )}
      </div>
    </div>
  );
}

export default AddPopup;
