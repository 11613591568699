import React, { useState } from "react";
import "./css/setting.css";
function Setting(props) {
  const { getData } = props;
  const [isEdit, setEdit] = useState(false);
  const [poolLength, setPoolLength] = useState(getData.pool_length);
  function handleSave() {
    setEdit(false);
  }
  return (
    <div className="setting_box">
      <div className="heading_edit_box">
        <h2 className="heading">Setting</h2>
        {isEdit ? (
          <p className="edit_button" onClick={handleSave}>
            Save
          </p>
        ) : (
          <p className="edit_button" onClick={() => setEdit(true)}>
            Edit
          </p>
        )}
      </div>
      <div className="input_info_box">
        <div className="first_half">
          <p className="profile_lable">Pool length</p>
          {isEdit ? (
            <input
              type="number"
              value={poolLength}
              onChange={(e) => setPoolLength(e.target.value)}
            />
          ) : (
            <p className="profile_title">{getData.pool_length}</p>
          )}
        </div>
        <div className="second_half">
          <p className="profile_lable">Metric</p>
          <p className="profile_title">KM</p>
        </div>
      </div>
    </div>
  );
}

export default Setting;
